import {createApp} from 'vue'
import Vant from 'vant';
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@vant/touch-emulator';
import api from "@/request/api";
import base from "@/request/base";
import audio from "@/audio/audio";
// 创建实例

const app = createApp(App) // 链式写法
    .use(Vant)
    .use(store)
    .use(router)

// .use(Lazyload, {
//     lazyComponent: true,
// })
// app.config.globalProperties.$isMobile = () => {
//     return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
// }

app.config.globalProperties.$base = base;
app.config.globalProperties.$api = api;
app.config.globalProperties.$audio = audio;
app.mount('#app')


//
// var devInnerHeight = 1080.0 // 开发时的InnerHeight
// var devDevicePixelRatio = 1.0// 开发时的devicepixelratio
// var devScaleFactor = 1.3 // 开发时的ScaleFactor
// var scaleFactor = window.require('electron').screen.getPrimaryDisplay().scaleFactor
// console.log(scaleFactor);
// var zoomFactor = (window.innerHeight / devInnerHeight) * (window.devicePixelRatio / devDevicePixelRatio) * (devScaleFactor / scaleFactor)
window.require('electron').webFrame.setZoomFactor(0.75) // 缩放75%

