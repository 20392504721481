<template>
  <div>
    <van-empty description="当前未登录，请先登录">
      <van-button round type="danger" class="bottom-button" to="/login">现在去登录</van-button>
    </van-empty>
  </div>
</template>

<script>
export default {
  name: "Nologin"
}
</script>

<style scoped>

</style>