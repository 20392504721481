<!--用户协议-->
<template>
  <div class="userAgreement">
    <h4 class="agreementh">新田格用户服务协议</h4>
    <div class="agreementContent">
      在您注册、登陆新田格练字系统、使用新田格练字系统服务（以下简称“本服务”），包括提供给您的更新版本或升级版本（依据本协议之外的其他协议提供给您的更新版本或升级版本除外）之前，请您务必仔细阅读并充分理解《新田格用户服务协议》（以下简称“本协议”）之全部条款，尤其是免除或者限制责任的条款、知识产权条款、法律适用及争议解决等。请您务必认真阅读。<br>
      您确认，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用新田格练字系统提供服务的一切行为独立承担责任。若您不具备前述主体资格，请在您的父母或法定监护人陪同下阅读本协议，并在取得他们对您使用服务的行为，以及对本协议全部条款的同意之后，方可使用本服务。新田格练字系统在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。如果您对本协议的任何条款表示异议，或者无法准确理解本协议任何条款，请不要访问和/或使用新田格练字系统及本服务。您的下载、安装、注册、登录、使用新田格练字系统等行为视为对本协议全部内容的接受，也意味您将接受本协议以及未来针对本协议更新版本的约束，并服从新田格练字系统的统一管理。<br>
      <b>一、服务协议的确认和变更<br></b>
      1. 本协议是您（以下简称“用户”）与新田格练字系统之间关于注册、登录新田格练字系统、使用新田格练字系统服务所订立的有约束力的法律文件。<br>
      2. 本协议的内容包括协议正文、新田格练字系统已发布或将来可能发布的其他声明、规则、公告，包括但不限于
      《新田格练字系统隐私政策》、《新田格练字系统积分服务条款》、《新田格练字系统用户充值购买协议》等。上述声明、规则、公告等均为本协议不可分割的组成部分，与本协议具有同等法律效力。<br>
      3. 新田格练字系统可能会不定期的更新本协议，用户可在新田格练字系统软件内页上查阅本协议的最新版本。新协议一经公布即代替原协议。如用户不接受更新后的协议，应立即停止使用新田格练字系统提供的服务。<br>
      <b>二、 新田格练字系统服务内容<br></b>
      新田格练字系统是一个为用户提供学习内容服务的互联网平台，服务内容包括但不限于：各个领域的专家（以下简称“专家”），以文字、音频、视频图片、游戏等形式分享个人见解、传播行业知识；用户可以阅读、收听、转发分享相关内容，并在各内容下发布形式包括但不限于文字、图片、音频、视频的评论（以下简称“评论”），从而实现与其他用户的交流互动。<br>
      <b>三、 账号注册、登录与使用<br></b>
      1.
      用户在使用本服务前须进行账号注册和/或登录。用户登录账号包括但不限于用户本人的手机号、邮箱账号、微信账号等。用户须按照新田格练字系统要求提供真实、及时、详尽及准确的个人资料，包括但不限于手机号、身份信息、密码及注册或更新注册信息时输入的所有信息（以下简称“个人资料”）。如个人资料有任何变动，用户须及时在新田格练字系统上予以更新。更改注册账号的行为，视为注册新账号，原账号内所有信息，包括但不限于已购服务、虚拟财产等（如有），不予转移。<br>
      2.
      用户在注册账号、填写个人资料时须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在个人资料中出现违法和不良信息，不得冒用、关联机构或社会名人，且用户保证其在注册和使用账号时，特别不得有以下情形：<br>
      （1）违反宪法或法律法规规定的；<br>
      （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
      （3）损害国家荣誉和利益的，损害公共利益的；<br>
      （4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；<br>
      （5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；<br>
      （6）煽动民族仇恨、民族歧视，破坏民族团结的；<br>
      （7）破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
      （8）散布谣言，扰乱经济秩序、社会秩序，破坏社会稳定的；<br>
      （9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
      （10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；<br>
      （11）含有法律、行政法规禁止的其他内容的。<br>
      3.
      若用户提供给新田格练字系统的个人资料不准确、不真实、含有违法或不良信息的，新田格练字系统有权不予注册并保留终止用户使用新田格练字系统各项服务的权利。若用户以虚假信息骗取账号注册或账号头像、个人简介等个人资料存在违法和不良信息的，新田格练字系统有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册账号名称的，新田格练字系统有权注销该账号，并向政府主管部门进行报告。<br>
      4.
      新田格练字系统用户账号所有权归临沂学志教育科技有限公司所有。新田格练字系统用户账号仅限于用户本人使用，禁止赠与、借用、租用、转让或售卖。如果新田格练字系统发现使用者并非账号初始注册人，有权不经通知收回账号而无需向账号使用人承担法律责任，由此带来的包括并不限于通讯中断、资料、虚拟财产清空、已购服务清空等损失由用户自行承担。<br>
      5. 用户同意使用本服务期间，接受新田格练字系统提供的商业信息，提供方式包括但不限于电子邮件、手机短信等合法方式。对于上述新田格练字系统发送的商业信息，用户有权取消订阅或者通过其他方式拒绝推送。<br>
      6. 用户同意，发生争议时，与注册、登录、使用新田格练字系统账号相关的一切数据和记录，包括但不限于所有登录、充值、购买记录和相关的使用统计数字以新田格练字系统的系统数据为准。<br>
      7.
      用户有责任妥善保管新田格练字系统账号信息及账号密码的安全，用户需要对新田格练字系统账号下的行为承担法律责任。由于账号、密码等信息外借、泄露或者被他人盗用而引起的法律责任，由用户自行承担。用户同意在任何情况下不使用其他用户的账号。用户同意若发现任何非法使用用户账号或安全漏洞的情况，有义务立即告知新田格练字系统。<br>
      8. 本平台可能涉及付费服务，付费方式包括但不限于购买课程进行账户充值、使用虚拟币购买新田格练字系统服务。<br>
      <b>四、双方权利与义务<br></b>
      1. 用户须通过合法渠道（包括但不限于各大应用市场、公司官网）下载、安装并使用新田格练字，这是您获得新田格练字使用授权的前提。<br>
      2. 用户一经注册或登录使用新田格练字，即视为用户同意公司的客服人员与其进行电话联系。<br>
      3. 用户在新田格练字内享有的服务内容由公司根据实际提供，并有权不时进行增加、删除、修改、或调整，包括但不限于：<br>
      （1）课程学习：用户可选择购买新田格练字系统内相关课程学习，老师进行音视频教学，所产生的课程费用由用户自行承担;用户购买课程后产生的课程回放，用户可在规定时间内随时观看复习，所产生的流量费用、上网费用由用户自行承担。<br>
      积分：用户可购买会员时使用、兑换礼品时使用，以及后期可兑换部分课程。具体以新田格练字系统详细积分使用说明为准。<br>
      勋章：用户可在线上或者线下通过完成相应的任务后获得勋章，如需邮寄到家用户可在新田格练字系统上申请，如果邮寄地址与注册信息地址不一致的需要重新填写邮寄资料，如不重新填写则视为默认注册地址，在邮寄过程中如因用户提供地址导致的损失全部由用户承担。<br>
      （4）班级群：用户在购买提供对应的课程后，可自愿加入到对应的班级群中与其他用户通过发送文字、图片内容等进行学习交流。<br>
      4. 使用新田格练字时产生的通讯流量费用由用户自行承担。<br>
      5.
      用户应妥善保管自己的账号、密码，不得转让、出借、出租、出售或分享予他人使用。否则公司有权根据实际情况暂时封停或永久查封此账号，当用户的账号或密码遭到未经授权的使用时，用户应当立即通知公司，否则未经授权的使用行为均视为用户本人行为。<br>
      6.
      公司有权随时对新田格练字提供的服务内容等进行调整，包括但不限于增加、删除、修改新田格练字系统的服务内容，一旦新田格练字系统的服务内容发生增加、删除、修改，公司将在相关页面上进行提示；如果用户不同意相关变更和修改，可以取消已经获取的服务并停止使用；如果用户继续使用新田格练字系统提供的服务，则视为用户已经接受全部变更、修改。除非另有明确约定，否则用户使用新田格练字内新增的服务内容将同样受本协议各项条款的约束。<br>
      7.
      新田格练字系统内提供的课程服务通常为收费服务。对于该等课程，公司有权自行确定课程的价格并不时调整，调整后的价格将在新田格练字系统中进行公示，如果用户不同意调整后的价格的，则用户可以在其原购买的课程结束后停止付费购买课程。如果用户在公司调整价格并公示后，仍继续购买课程的，则视为用户同意该调整后的价格。<br>
      8. 新田格练字系统提供课程体验，用户一旦购买课程，则视为已经体验过课程，是经过慎重考虑作出的选择，公司将不再提供退款服务。
      公司有权保留已终止服务的用户的所有使用信息，对用户在使用付费服务期间发生的违反本协议及相关规定的行为，公司仍有权行使本协议规定的权利并追究用户的法律责任。<br>
      9.
      公司不时将对付费服务提供优惠政策或开展优惠活动，该等优惠政策或优惠活动以新田格练字系统上的实时信息为准，用户知悉并认可，公司有权自行决定是否提供优惠政策或开展优惠互动，以及优惠政策或优惠活动的内容、条件和有效期，并不时进行调整或终止。<br>
      10. 如果用户通过合法方式获得了新田格练字相关服务的优惠券或积分等其他优惠方式的，则用户知悉并认可，该等优惠券或折扣必须满足一定条件才能使用，以优惠券上的信息提示和优惠券使用说明，或新田格练字公示的折扣使用说明为准。
      优惠券或积分不兑现（即不直接兑换现金）、不找零（即订单金额不足抵用券金额的，抵用券剩余金额无效）、过期作废；优惠券或积分仅限同一用户账号使用，严禁出售、转让或共享，如经公司发现用户存在出售、转让或共享优惠券或积分等其他优惠方式的，该优惠券或积分将予以作废处理，且公司有权视情节严重程度予以封号处理，并保留追究相关责任人法律责任的权利。<br>
      11.
      新田格练字系统内的积分、碎片、勋章等的获得公司不提供充值功能，只能通过课程学习、互动、交流方式获得。凡以任何形式的刻意开通课程以获得积分、碎片、勋章等的行为跟新田格练字系统和母体公司（临沂学志教育科技有限公司）无关，新田格练字系统和母体公司（临沂学志教育科技有限公司）也不承担任何的民事和法律责任，由此产生的任何后果由用户自行承担。<br>
      12.
      公司禁止以任何形式倒卖、贩售、出租、出借、转让用户账号、优惠券或积分等其他优惠方式及使用第三方渠道（即非新田格练字官方渠道）代为充值的行为，一经查明，公司将采取包括但不限于封号、封禁IP、追究相关责任人法律责任的处理措施。<br>
      <b>五、用户须知<br></b>
      1. 用户必须为自己账号下发生的一切行为负责。用户须保证在新田格练字系统的所有行为，包括但不限于发布、传播相关内容符合法律法规、相关政策及新田格练字系统不定时更新的声明、规则、公告，符合公序良俗等基本社会规范。用户承诺：<br>
      (1) 用户在新田格练字系统上发布信息或者利用本服务时必须符合中国有关法律、法规、规章、政策，不得在新田格练字系统的网页上或者利用新田格练字系统服务制作、复制、发布、传播以下信息：<br>
      (a) 违反宪法或法律法规规定的；<br>
      (b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
      (c) 损害国家荣誉和利益的，损害公共利益的；<br>
      (d) 歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；<br>
      (e) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；<br>
      (f) 煽动民族仇恨、民族歧视，破坏民族团结的；<br>
      (g) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
      (h) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br>
      (i) 以非法民间组织名义活动的；<br>
      (j)散布谣言，扰乱经济秩序、社会秩序，破坏社会稳定的；<br>
      (k)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
      (l)侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；<br>
      (m)含有法律、行政法规禁止的其他内容的。<br>
      (2) 用户不得利用新田格练字系统从事制作、复制、上传、发布、传播如下内容：<br>
      (a) 含有任何性或性暗示以及任何其他低俗类信息；<br>
      (b) 未经新田格练字系统允许发布广告；<br>
      (c)侵害他人合法权益，包括但不限于名誉权、肖像权、知识产权、表演者权、商业秘密、隐私权等。<br>
      (3) 用户在新田格练字系统上发布信息或者利用本服务时，应当采取措施，防范和抵制制作、复制、发布含有下列内容的不良信息：<br>
      (a)使用夸张标题，内容与标题严重不符的；<br>
      (b)炒作绯闻、丑闻、劣迹等的；<br>
      (c)不当评述自然灾害、重大事故等灾难的；<br>
      (d)带有性暗示、性挑逗等易使人产生性联想的；<br>
      (e)展现血腥、惊悚、残忍等致人身心不适的；<br>
      (f)煽动人群歧视、地域歧视等的；<br>
      (g)宣扬低俗、庸俗、媚俗内容的；<br>
      (h)可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；
      (i)其他对网络生态造成不良影响的内容。<br>
      (4) 为确保新田格练字系统和用户的利益，用户在使用新田格练字系统时，不应进行如下行为（该行为是指使用新田格练字系统帐户和新田格练字系统所进行的任何行为，包括但不限于注册登录、帐号运营、管理及推广以及其他行为）：<br>
      (a) 强制、诱导其他用户关注、点击链接页面或分享信息；<br>
      (b)虚构事实、隐瞒真相以误导、欺骗他人；<br>
      (c)未经新田格练字系统书面许可使用插件、外挂或其他第三方工具、服务接入本服务和相关系统；<br>
      (d)利用新田格练字系统及新田格练字系统帐户从事违法犯罪活动；<br>
      (e) 不得利用非法软件或其他作弊方式进行欺诈行为（包括但不限于刷订阅数、刷评论数、刷点击量等），也不得利用系统漏洞来获得不正当的非法利益；<br>
      (f)不得利用党旗、党徽、国旗、国徽、国歌等代表党和国家形象的标识及内容，或者借国家重大活动、重大纪念日和国家机关及其工作人员名义等，违法违规开展网络商业营销活动；
      (g)不得通过发布、删除信息以及其他干预信息呈现的手段侵害他人合法权益或者谋取非法利益；<br>
      (h)不得利用深度学习、虚拟现实等新技术新应用从事法律、行政法规禁止的活动；<br>
      (i)不得通过人工方式或者技术手段实施流量造假、流量劫持以及虚假注册账号、非法交易账号、操纵用户账号等行为，破坏网络生态秩序；<br>
      (j) 其他以任何不合法的方式、为任何不合法的目的、或以任何与本条款不一致的方式使用、干扰本服务。<br>
      2. 新田格练字系统有权对用户发布的内容在任何时间节点进行审核，若新田格练字系统认为用户发布的内容存在违法、侵权或其他不恰当的情况，新田格练字系统有权不经用户事先同意即采取屏蔽、删除内容、暂停用户账号的使用、注销用户账号等措施。<br>
      3. 用户承诺，非经新田格练字系统同意，用户不能利用新田格练字系统内容进行复制、销售、传播或其他除本服务外的商业用途。如用户有需要将服务用于商业用途，应书面通知新田格练字系统并获得新田格练字系统的明确授权。<br>
      4.
      用户承诺，不将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；不以技术方式攻击或破坏或改变新田格练字系统的部分或全部、或干扰其运行；不以非法方式获取或使用新田格练字系统的任何软件、代码或其他技术或商业信息；不对新田格练字系统运行的任何程序进行反向工程、反向编译、反向汇编或改写。<br>
      5. 用户在此免费地、永久性地、不可撤销地在全世界范围内授权新田格练字系统在界面设计、推广等活动中（包括但不限于频道设计、对外宣传、产品介绍等）免费使用其姓名（包括但不限于笔名、网名及任何代表用户身份的文字符号）、肖像。<br>
      6.
      用户同意授予新田格练字系统在全世界范围内免费地、永久性地、不可撤销地使用和转授权用户通过新田格练字系统发布或用户使用新田格练字系统服务过程中形成的信息及内容（包括但不限于文字、图片、音频、视频）等，新田格练字系统可以通过包括但不限于使用、复制、发行、改编、汇编、传播、修改、转授权、摄制、翻译、创建衍生作品、出版、表演、放映等方式使用相关内容。<br>
      7. 用户许可新田格练字系统有权就任何主体侵犯上述信息及内容的侵权而单独提起诉讼或开展其他法律行动。<br>
      8. 本平台有权视严重情况，对违反本协议的用户采取以下单项或多项措施进行处罚：<br>
      （1）暂停/终止提供全部或部分服务；<br>
      （2）删除违规内容；<br>
      （3）暂时/永久封禁账号；<br>
      （4）扣除、清空部分或全部虚拟财产；<br>
      9.
      若新田格练字系统认为用户账号内的虚拟财产（包括但不限于积分等）来源异常，新田格练字系统有权对账号进行暂时冻结并进行调查。如调查后判定存在来源异常情形，则新田格练字系统有权将来源异常部分进行没收，情节严重者将进行账号封禁；来源异常情形包含但不限于：非新田格练字系统认可的渠道充值或购买，利用系统BUG、漏洞或外挂获得，其他非正当方式来源获得等。<br>
      10.
      用户违反本协议，导致第三方向新田格练字系统及其关联公司、合作公司提出索赔等要求的，用户同意承担全部法律责任，包括但不限于赔偿金、罚金、和解费、诉讼仲裁费、合理的律师费支出等，并同意赔偿因此给新田格练字系统造成的损害。用户承担赔偿责任的方式包括但不限于根据新田格练字系统的书面通知向其支付与损失等额的资金、以累计的虚拟财产抵偿相关损失等。<br>
      <b>六、平台管理<br></b>
      公司有权对用户在新田格练字系统发布的内容及使用新田格练字的行为进行监督和审核，包含但不限于用户评论、图片上传、课中聊天、班级群内的聊天、用户上传到新田格练字平台中的文档、图片、音频、视频等，用户也可通过举报的方式对使用过程中发现的不良内容进行举报。一旦发现作弊及发布违反新田格练字规定的内容，公司将于24小时内删除内容并对违规用户进行相应处理。<br>
      用户可通过新田格练字系统允许的学习行为获得积分，包含但不限于：完成游戏、按时到课、按时提交课后作业、课中参与答题互动，具体积分获得方法需参照平台最新的积分获取规则。积分获取规则由公司制定并公布在新田格练字中，并有权不时进行增加、删除、修改、或调整。新田格练字系统有权对通过作弊行为获取的积分进行酌情扣除。<br>

      1. 作弊行为与处罚方法<br>
      （1）作弊的行为包括但不限于通过非公平、不正当途径获取积分、利用系统漏洞获取优惠或做异常使用、违规贩卖优惠券、使用第三方渠道（即非新田格练字官方渠道）代充积分等。对于作弊行为的判断，公司保留最终解释权。特别注意：任何形式的刷积分，转积分，送积分等都属于作弊行为。<br>
      （2）作弊的处罚指向所有通过非正当行为获得的账号、积分、优惠、购买的课程等，作弊所得将被全部扣除。涉嫌作弊行为一经初步确认，涉及该涉嫌作弊行为的所有相关用户的登录账号将被酌情扣分、有限期封禁，情节严重者会被永久封禁，甚至进行刑事追诉，涉及该涉嫌作弊行为的所有购买的课程将被按实际情况酌情退款或直接取消上课资格。涉嫌作弊内容的发布者在新田格练字平台上发布的内容（不论作弊与否）均将被严肃处理，情节严重者将被封禁其登录账号及取消该账号购买课程的上课资格。<br>
      2. 聊天、评论、文档、知识删除原则<br>
      在新田格练字产品使用期间，公司有权删除不符合法律法规、部门规章、公序良俗的提问、评论、聊天内容等，以及公司认为跟新田格练字产品和服务不相关或者认为不适当、不合理的用户内容，包括但不限于，含有色情、暴力、恐怖内容，具有广告性质，含有反动内容，含有人身攻击内容，含有违背伦理道德内容，具有恶意、无聊和灌水性质，涉及违法犯罪的内容，以及其他违反法律及新田格练字平台管理制度的内容。<br>
      3. 对账号的管理原则<br>
      （1）您应保证您注册、登录并使用的账号符合中国相关法律法规的规定，符合《互联网用户账号名称管理规定》，符合本协议的各项规定。公司有权对您注册、登录并使用的账号名称、头像和简介等注册信息进行审核，您确保您登录本软件使用的用户账号名称、头像和简介等信息中不得出现违法和不良信息，不存在以虚假信息骗取账号名称注册的情形；您应确保您登录新田格练字使用的用户账号名称不得为您冒用关联机构或社会名人注册的账号名称。<br>
      （2）如果您以虚假信息骗取账号名称注册，或您的账号头像、简介等注册信息存在违法和不良信息的，公司有权采取通知限期改正、暂停使用、注销登记等措施；如果您冒用关联机构或社会名人注册账号名称的，公司有权注销您的账号，并向互联网信息内容主管部门报告。<br>
      （3）凡用户存在作弊行为，发布广告，发布黄色、反动内容，发布不文明内容，抄袭，模仿管理人员ID，用以假冒管理人员或破坏管理人员形象，模仿或盗用他人ID
      个人签名,发布的内容包含有严重影响网友浏览的内容或格式，或者存在其他扰乱新田格练字秩序或影响新田格练字正常经营的行为的，公司有权删除其相应账号，情节严重者，将酌情封禁对应IP。<br>
      （4）用户不得转让、出租、销售、分享其账号，否则，公司有权注销您的账号，且不对账号内的已购买未消费的课程进行退款。<br>
      （5）新田格练字系统暂不提供账号注销功能，如需注销账户请联系后台人工客服进行操作。注销账号之后此账号内的数据无法恢复，包括但不限于账户内的资产信息如积分、优惠券等；账号信息内容也将全部清除，包含但不限于该账号发布过的文章、评论等。注销行为操作后无法撤销，请用户谨慎申请。<br>
      4. 投诉、举报制度<br>
      如果您认为新田格练字中的用户评论、聊天内容、文档、图片、音频、视频等内容存在违反法律法规及新田格练字平台相关管理规定的，涉嫌侮辱或者诽谤他人，或者侵犯您或第三方的商标权、著作权或其他合法权利情形的，您可以通过新田格练字正规渠道进行举报、投诉，或者向新田格练字官方邮箱chinaxuezhi@163.com发送权利通知书，公司将在收到您的投诉后将依据中国法律法规和政府规范性文件及新田格练字平台管理规定采取措施进行处理，包括但不限于移除相关内容或屏蔽相关链接，以及其他公司认为适当的处理方式，情节严重者，公司有权向政府相关部门进行举报。<br>
      <b>七、知识产权<br></b>
      1.
      公司拥有新田格练字的所有权，包括其中的著作权、商标权、专利及专利申请权、技术秘密等知识产权。新田格练字受中华人民共和国著作权法的保护，用户不得删除或以其他方式改变新田格练字上附带的版权、商标或其他所有权声明或标识。用户不得对新田格练字进行反向工程、反向编译或反汇编，不得试图进行任何获得新田格练字源代码的访问或行为，不得发行、出租、信息网络传播、翻译新田格练字系统。<br>
      2.
      新田格练字的用户不能侵犯他人合法权利，包括但不限于他人包括著作权、商标权、专利权在内的知识产权，以及名誉权、肖像权等人身权利、财产权利。一旦由于用户侵犯他人合法权利引起的法律纠纷，其责任在于用户本人，与新田格练字及公司无关，该用户应自行解决且需保证公司不会因此遭受任何损失。<br>
      3.
      公司享有新田格练字中所提供的课程的全部及完全的知识产权，包括但不限于著作权，未经公司事先书面许可，任何人不得将该等课程进行翻录，并不得以任何方式自行或允许第三方使用、复制、传播、散播、销售该等翻录的课程，或进行其他侵犯公司合法权利的行为，不论是否以盈利为目的，否则，公司将追究相关当事人的法律责任。<br>
      4.
      对于用户上传、发表到新田格练字上的任何内容，包括但不限于视频、音频、文档、图片等，用户保证并承诺为其原创的内容、作品或获得了合法的授权，有权授权新田格练字在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。并且，用户许可公司有权利就任何主体对前述内容的侵权而单独采取法律行动（包括但不限于诉讼、举报、律师函等形式）。<br>
      5.
      用户的言论、上传的视频、音频、文档、图片等内容侵犯了第三方合法权利的，第三方提出异议的时候，新田格练字有权先行删除相关的内容，提出异议者和用户之间以和解、调解、仲裁、诉讼等方式解决了争议后，以此为依据，新田格练字在收到用户的有关申请后可以恢复被删除的内容。<br>
      6. 未经本公司事先许可，禁止任何人使用任何机器人、爬虫程序、其他自动设备，或手动程序等来监视或复制新田格练字网页或其所包含的任何内容。否则，公司有权依法追究相关人员的法律责任。<br>
      <b>八、不允诺担保、责任有限及免责<br></b>
      1.
      不允诺担保：用户上传的内容，其提问、回答、发言仅代表用户个人观点，与公司无关，且公司不对用户上传的内容、发表的回答或评论的正确性、准确性、真实性进行保证。新田格练字为按现状提供，包含瑕疵及缺陷，公司不提供任何其他担保。公司不允诺其他无论是明示的还是默示的保证和担保，包括但不限于对特定目的的适销性和适应性，不侵权，以及任何出自交易过程或商业惯例的保证和条件。从公司或他处获取的任何建议或信息，不论是口头的还是书面的，都不会对公司产生本协议明确约定之外的其他保证和担保。<br>

      2.
      责任有限：适用法律所允许的最大范围内，不论出于何种原因，公司对您的全部责任为您使用新田格练字的产品和服务而向公司支付的费用总额。如果公司免费向您提供了新田格练字服务，则公司无义务对您承担任何赔偿责任。任何情况下，公司不对出自本协议或与本协议有关的或与新田格练字的运行有关的任何特殊的、偶然的、惩罚性或间接的损害（包括但不限于数据损失、利润损失或营业损失）或采购替代产品或服务承担责任，不论该等责任是基于合同，担保，侵权（包括过失），严格责任或其他，也不论公司是否被事先告知了损失或损害发生的可能性。即使本协议约定的有限补救措施未能实现其根本目的，前述的责任限制依然适用。<br>
      3. 免责：如因下述所列任何原因而造成的用户资料泄露、丢失、被盗用或被篡改等，公司均有权获得免责：<br>
      a) 任何因政府管制而造成的暂时性关闭等影响网络正常浏览的不可抗力原因；<br>

      b) 任何计算机系统问题、黑客攻击、计算机病毒侵入或发作的原因；<br>
      c) 任何因网络安全问题导致的账户或银行卡信息泄露，该风险可能会给用户造成经济损失，用户承诺自行承担向侵权方追究责任及追究责任不能的后果；<br>
      d) 任何因用户个人原因导致的用户资料泄露、丢失、被盗用或被篡改等；<br>
      e) 其他不能归责于公司的原因。<br>
      <b>九、用户隐私声明<br></b>
      1.
      公司仅出于向用户提供更优质、更个性化服务的目的收集用户的信息，且公司重视未成年人的个人信息保护，如您为未成年人，请务必由您的监护人仔细阅读本隐私条款，并在征得监护人同意的前提下使用新田格练字系统或向新田格练字系统提供相关用户信息。<br>
      2. 在用户使用新田格练字系统的过程中，公司将遵循合法、正当、必要的原则，收集用户使用新田格练字的相关信息，包括但不限于：<br>
      (1) 用户注册或登录的账户信息，例如用户注册账户使用的电话号码、姓名或昵称，用户自行填写的班级、学校等；<br>
      (2) 用户上传的信息，例如用户的发帖、评论、分享、用户主动填写的信息或用户与新田格练字客服联系时提供的相关信息等；<br>
      (3) 用户使用新田格练字过程中产生的信息。公司会根据用户授予的权限，接收并记录用户所使用的设备相关信息，例如设备属性信息、设备位置信息、设备连接信息等；<br>
      (4) 新田格练字系统根据用户授权通过合法方式收集的其他用户信息。<br>
      3.
      公司非常重视用户的信息安全，并尽最大合理努力采取各种合理的物理、电子和管理方面的安全措施来保护用户信息，确保用户信息不会被泄漏、篡改、毁损或丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制、信息访问权限控制等。且公司不会非法出售、非法向他人提供用户信息；公司不会将用户的该等信息提供给任何第三方，也不会用于任何其他目的和用途，但是以下情形除外：<br>
      1）经用户事先同意，向第三方披露；<br>
      2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      3）公司与第三方合作向用户提供新田格练字平台的相关服务或其他网络服务，且该等第三方同意承担与公司同等的保护用户隐私的责任；<br>
      4）其他根据法律、法规或者政策应进行的披露。<br>
      4. 本隐私声明仅为概要性规定，公司会单独发布用户隐私政策对收集、使用、保护用户信息进行规定为，如本隐私声明条款与另行发布的用户隐私政策不一致的，以后者为准。<br>
      十、其他<br>
      1. 如果新田格练字平台上某一子服务附有单独的使用协议的，则该子服务的使用将受该单独使用协议的约束。<br>
      2. 本协议的签订、生效、履行、争议的解决均适用中华人民共和国法律。<br>
      3. 有关本协议的争议应通过友好协商解决，如果协商不成，该争议将提交公司所在地有管辖权的法院诉讼解决。<br>
      4. 用户对新田格练字平台的意见及建议可通过客服邮箱chinaxuezhi@163.com与公司进行联系。<br>

      生效日期：2021年1月1日

    </div>
  </div>
</template>

<script>
export default {
  name: "userAgreement"
}
</script>

<style scoped>
.userAgreement {
  width: 90%;
  margin: 20px auto;
  text-align: center;
  line-height:30px;
}
.agreementh{
  font-size: 20px;
}

.agreementContent{
  text-align: left;
  font-size: 14px;
}
</style>
