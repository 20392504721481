<template>
	<div class="numberStyle_div">
		<div class="main">
			<div class="main_l">
				<div class="main_l_title">{{index_title}}</div>
				<div class="main_l_list">
					<div @click="Checkzi(item)" :class="nowhanzi==item?'checkhanzi hanzistyle':'hanzistyle'"
						:key="index" v-for="(item,index) in $store.state.zierData">{{item}}</div>
				</div>
				<!-- <Yswiper :chinesecharactersarr="$store.state.zierData" @changehanzi="changehanzi"></Yswiper> -->

			</div>
			<div class="main_middle">
				<img class="guagou" src="../assets/image/guagou.png" alt="">
				<img class="guagou" src="../assets/image/guagou.png" alt="">
			</div>
			<div class="main_r">
				<div class="main_r_1">
					<div class="div_svg">
						<svg height="550px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.28 484.97"
							id="grid-background-target" class="svg_style">
							<NewSvg :Switchingfields="Switchingfields" />
						</svg>
						<transition name="van-fade">
							<div style="background-size: cover;
		background-repeat: no-repeat;" v-show="!shixun" ref="shixunref" class="svgStyle"></div>
						</transition>
					</div>
					<div class="main_r_sx">
						<img class="shang" src="../assets/image/shangyibi.png" alt="" @click="up_character()">
						<img @click="quanping" class="shang" src="../assets/image/quanping2.png" alt="">
						<img class="xia" src="../assets/image/xiayibi.png" alt=""
							@click="down_character('down', hanzi)">
					</div>
				</div>

				<div class="main_r_2">
					<div>
						<div class="main_r_2title">书写讲解</div>
						<div class="main_r_2info">
							<div @click="handleSpeak()" style="flex-wrap: nowrap;white-space: nowrap;"
								class="flex align-center">
								拼音：{{ simpleExp["pinyin"] }}
								<img src="../assets/image/laba.png" class="laba" alt="">
							</div>
							<div>笔画：{{ simpleExp["bihuashu"] }}</div>
							<div>部首：{{simpleExp["bushou"]}}</div>
						</div>
					</div>
					<div class="main_r_2foot">
						<button class="btn11" @click="characterAnimation('strokeOrderAnimation', hanzi) + stopshixun()">
							笔顺学习
						</button>
						<button class="" :class="buttonstatus?'check_bg btn11':'btn11'"
							@click="switchingTG() + $audio(0)">笔画定位</button>
						<button class="btn11" @click="showPopup()">规律要点</button>
						<button class="btn11" @click="characterAnimation('writerPractice', hanzi) + stopshixun()">
							书写练习
						</button>
						<button class="btn11" @click="characterAnimation('silentWriting', hanzi) + stopshixun()">
							默写检查
						</button>
						<button class="btn11" @click="$router.push('/video')">视频讲解</button>
					</div>
				</div>
			</div>
		</div>




		<div v-show="false">
			<div style="height: 100px"></div>
			<br />
			<div class="btnStyle" style="margin-top: 200px">
				<button class="btn1">上一笔</button>
				<button class="btn1">下一笔</button>
			</div>
			<div class="btnStyle">
				<button class="btn1" @click="characterAnimation('strokeOrderAnimation', hanzi) + stopshixun()">
					笔顺动画
				</button>
				<button class="btn1" @click="switchingTG() + $audio(0)">占格定位</button>
				<button class="btn1" @click="showPopup()">书写要点</button>
			</div>
			<div class="btnStyle">
				<button class="btn1" @click="characterAnimation('writerPractice', hanzi) + stopshixun()">
					书写练习
				</button>
				<button class="btn1" @click="characterAnimation('silentWriting', hanzi) + stopshixun()">
					默写测评
				</button>
				<button class="btn1" @click="$router.push('/video')">视频跳转</button>
			</div>
		</div>

		<van-popup @close="popShow=false" round overlay-class="pop_style" v-model:show="popShow" :lazy-render="false"
			:style="pop_style">
			<div class="pop_con">
				<div class="div_svg" style="width:73%">
					<svg class="svg_style" height="700px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.28 484.97"
						id="grid-background-target1">
						<NewSvg :Switchingfields="Switchingfields" />
					</svg>
					<transition name="van-fade">
						<div style="background-size: cover;
				background-repeat: no-repeat;" v-show="!shixun" ref="shixunref2" class="svgStyle"></div>
					</transition>
				</div>
			</div>
		</van-popup>
		<div class="align-center fixed">
			<img @click="popShow=false" src="../assets/image/close_b.png" class="close_icon" alt=""
				v-if="popShow==true">
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	// import Yswiper from "@/components/Yswiper";
	import NewSvg from "@/components/NewSvg";
	import Footer from "@/components/Footer"
	import {
		useStore
	} from "vuex";
	import HanziWriter from "hanzi-writer";
	import audio from "@/audio/audio";
	import base from "@/request/base";
	import {
		defineComponent,
		ref
	} from "vue";

	const synth = window.speechSynthesis;
	const msg = new SpeechSynthesisUtterance();

	export default defineComponent({
		name: "charachterExercise",
		components: {
			NewSvg,
			Footer
			// Yswiper
		},
		setup() {
			const store = useStore();

			// for (const storeKey of store.state.zierData) {
			// 	axios.get(
			// 		`https://oss.newtiange.com/hanzi/hanzi-writer-data/${storeKey}.json`
			// 	);
			// }

			const popShow = ref(false);
			let updown = ref({
				cls: "",
				hz: "",
				num: 0,
				maxnum: 0,
			});
			const up_character = () => {
				//console.log(hanzi);
				let content = document.getElementById("grid-background-target");
				if (content.childNodes.length === 1 || updown.value.cls === "anm") {
					return false;
				} else {
					content.removeChild(content.lastChild);
					content.removeChild(content.lastChild);
					updown.value.num -= 1;
				}
			};

			const down_character = (cls, hanzi) => {
				let content = document.getElementById("grid-background-target");
				if (
					updown.value.cls === "down" &&
					updown.value.hz === hanzi &&
					updown.value.num !== updown.value.maxnum
				) {
					HanziWriter.create(
						content,
						hanzi,
						store.state.updownstroke
					).animateStroke(updown.value.num);
					updown.value.num += 1;
				} else {
					while (content.childNodes.length !== 1) {
						content.removeChild(content.childNodes[1]);
					}
					HanziWriter.loadCharacterData(hanzi).then((r) => {
						updown.value = {
							cls: "down",
							hz: hanzi,
							maxnum: r.strokes.length,
							num: 1,
						};
					});
					HanziWriter.create(
						content,
						hanzi,
						store.state.updownstroke
					).animateStroke(0);
				}
			};

			const characterAnimation = (options_can, hanzi) => {
				// if (this.buttonstatus) {
				// 	this.buttonstatus = false
				// }
				// this.buttonstatus = options_can
				audio(0);
				let content = document.getElementById("grid-background-target");
				while (content.childNodes.length !== 1) {
					content.removeChild(content.childNodes[1]);
				}
				updown.value.cls = "anm";
				switch (options_can) {
					case "cl": // 笔顺动画
						HanziWriter.create(content, hanzi, store.state.stroke);
						break;
					case "strokeOrderAnimation": // 笔顺动画
						HanziWriter.create(
							content,
							hanzi,
							store.state.strokeOrderAnimation
						).animateCharacter();
						break;
					case "writerPractice": // 书写练习
						quiz(HanziWriter.create(content, hanzi, store.state.writerPractice));

						break;
					case "silentWriting": // 默写测评
						quiz(HanziWriter.create(content, hanzi, store.state.silentWriting));
						break;
				}
			};
			const quiz = (writer) => {
				writer.quiz({
					onMistake: () => {
						// 书写错误的方法
						/*audio(0);*/
					},
					onCorrectStroke: () => {
						// 书写正确的方法
					},
					onComplete: () => {
						// 书写完成
						audio(0);
					},
				});
			};
			const img = `${base.ass}image/sc.svg`;

			return {
				characterAnimation,
				img,
				down_character,
				up_character,
				popShow
			};
		},
		data() {
			return {
				hanzi: this.$store.state.zierData[0],
				Switchingfields: true,
				shixun: true,
				nowhanzi: this.$store.state.zierData[0],
				simpleExp: [],
				bgstatus: 1,
				buttonstatus: false,
				// popShow: false,
				pop_style: {
					width: '60%'
				}
			};
		},
		mounted() {
			this.getInfo()
			this.characterAnimation("cl", this.hanzi);
			// console.log('cl', 123)
			// console.log(this.hanzi, this.$store.state.cont_zi)
			this.$refs.shixunref.style.background = `url('${this.$base.ass}yaodian/${this.hanzi}.svg')`;
			this.$refs.shixunref.style.backgroundSize = "390.79px 431.79px";
		},
		computed: {
			index_title() {
				const sa = this.$route.meta.title
				if (sa === 'project') {
					var a = this.$store.state.kmcData
					return a.slice(a.indexOf('.') + 1, a.length)
				} else {
					return sa
				}
			},
		},
		methods: {
			quanping() {
				this.popShow = true;
				this.$refs.shixunref2.style.background = `url('${this.$base.ass}yaodian/${this.hanzi}.svg')`;
				this.$refs.shixunref2.style.backgroundSize = "390.79px 431.79px";
				let content = document.getElementById("grid-background-target1");
				while (content.childNodes.length !== 1) {
					content.removeChild(content.childNodes[1]);
				}
				HanziWriter.create(content, this.hanzi, this.$store.state.stroke);
			},
			getInfo() {
				this.$api.post('treatment', {
					zi: this.hanzi,
				}).then((res) => {

					this.simpleExp = res.data[0]
				})
			},
			handleSpeak() {
				msg.text = this.hanzi; // 文字内容: 小朋友，你是否有很多问号
				msg.lang = "zh-CN"; // 使用的语言:中文
				msg.volume = 8; // 声音音量：1
				msg.rate = 0.2; // 语速：1
				msg.pitch = 4; // 音高：1
				synth.speak(msg); // 播放
			},

			// 语音停止
			handleStop(e) {
				msg.text = e;
				msg.lang = "zh-CN";
				synth.cancel(msg);
			},
			showPopup() {
				this.shixun = this.shixun !== true;
				this.$audio(0);
				if (this.buttonstatus) {
					this.buttonstatus = false
				}
			},
			stopshixun() {
				this.shixun = true;
			},
			changehanzi(ele) {
				console.log(ele)
				this.stopshixun();
				this.hanzi = ele;
				this.$refs.shixunref.style.background = `url('${this.$base.ass}yaodian/${ele}.svg')`;
				this.$refs.shixunref.style.backgroundSize = "390.79px 431.79px";
				this.characterAnimation("cl", ele);
			},
			Checkzi(ele) {
				this.nowhanzi = ele
				this.stopshixun();
				this.hanzi = ele;
				this.getInfo()
				this.$refs.shixunref.style.background = `url('${this.$base.ass}yaodian/${ele}.svg')`;
				this.$refs.shixunref.style.backgroundSize = "390.79px 431.79px";
				this.characterAnimation("cl", ele);
			},
			switchingTG() {
				//if 简化
				this.buttonstatus = !this.buttonstatus
				this.Switchingfields = this.Switchingfields !== true;
				// this.showSound("../assets/sound/qiehuan.mp3")
			},
		},
	});
</script>
<style>
	button::after {
		outline: none;
	}

	html,
	body {
		/* width: 100%; */
		/* height: 100%; */
		/* margin: 0;
		padding: 0; */
		/* overflow: auto; */
	}
</style>
<style lang="scss" scoped>
	.svg_style {
		width: 100%;
		height: 100%;
	}

	.pop_con {
		background-image: url('../assets/image/pop_bg.png');
		height: 82vh;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 100%;
		overflow: hidden;
		padding-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.close_icon {
		width: 125px;

	}

	.fixed {
		position: fixed;
		z-index: 2300;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		bottom: 10px;
	}

	.check_bg {
		background-color: #02427D !important;
		color: #fff !important;
	}

	.cls-1 {
		stroke: #02427D !important;
		fill: #fff !important;
	}

	@import "../assets/css/mian.css";

	div::-webkit-scrollbar {
		width: 6px;
		height: 40%;
	}

	div::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		opacity: 0.2;
		background-color: #02427D !important;
		// background: fade(#02427D, 100%);
	}

	div::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: fade(#02427D, 100%) !important;
	}

	@font-face {

		font-family: 'kt';
		src: url("@/assets/fonts/楷体_GB2312.ttf");
	}

	.numberStyle_div {
		height: 100%;
		height: 100vh;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transition: background 3s ease;
		background-position: 50% 50%;
		padding: 30px;
		background-image: url('../assets/image/home_bg.jpg');
	}

	.main {
		display: flex;
		align-items: center;
		margin: 30px;
		height: 85vh;
		justify-content: center;
		background-position: 50% 50%;
		background-image: url('../assets/image/blue_rgb.png');
		background-size: 100% 100%;
		padding: 0 25px;

		.main_l {
			width: 30%;
			height: 78vh;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: background 3s ease;
			background-position: 50% 50%;
			padding: 30px;
			text-align: center;
			margin-right: -50px;
			background-image: url('../assets/image/lianzi_lbg.png');

			.main_l_title {
				font-size: 40px;
				color: #02427D;
				font-weight: 600;
				font-family: 'cn';
			}

			.main_l_list {
				display: flex;

				justify-content: space-between;
				flex-wrap: wrap;
				margin: 20px;
				overflow-y: scroll;
        scrollbar-color: #0b2e13;
				height: 51vh;
			}

			.checkhanzi {
				color: #02427D !important;
				background-image: url('../assets/image/tiangelan.png') !important;
			}

			.hanzistyle {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 16px;
				text-align: center;
				line-height: 130px;
				font-size: 88px;
				color: #909399;
				width: 45%;
				font-family: 'kt';
				height: 30%;
				background-size: 100% 100%;
				background-image: url('../assets/image/tiange.png');
			}
		}

		.main_r {
			display: flex;
			align-items: center;
			margin-left: -50px;
			width: 70%;
			height: 78vh;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: background 3s ease;
			background-position: 50% 50%;
			padding: 45px 0;
			background-image: url('../assets/image/hanzi_r_bg.png');

			.main_r_1 {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				height: 100%;
				width: 66%;
			}

			.main_r_2 {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				color: #02427D;
				height: 100%;
				width: 30%;

				.main_r_2title {
					font-size: 40px;
					font-family: 'cn';
					color: #02427D;
					margin: 20px 0;

				}

				.main_r_2info {
					line-height: 50px;
					font-size: 26px;

					.laba {
						width: 26px;
						height: 26px;
						margin-left: 20px;
					}
				}

				.main_r_2foot {
					display: flex;
					align-items: center;
					height: 34%;
					justify-content: space-between;
					flex-wrap: wrap;

					.btn11 {
						width: 43%;
						height: 19%;
						border: 1px solid #02427D;
						color: #02427D;
						border-radius: 30px;
						margin: 15px 0;
						background-color: #fff;
					}

					.btn2 {
						border-radius: 30px;
						width: 120px;
						height: 40px;
						color: #fff;
						margin: 15px 0;
						border: 1px solid #02427D;
						background-color: #02427D;
					}
				}
			}



			.main_r_sx {
				// width: 450px;
				padding: 0 10%;
				display: flex;
				align-items: center;
				justify-content: space-around;


				.shang,
				.xia {
					width: 23%;
					// height: 50px;
				}
			}
		}

		.main_middle {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			z-index: 20;
			height: 50vh;
		}
	}




	.imgSize {
		width: 100%;
		max-width: 500px;
		height: 100%;
		margin: auto;
	}

	.svgStyle {
		width: 81%;
		height: 98%;
		position: absolute;
		left: 8%;
		top: -1%;
		background-size: 100% 100% !important;
		background-repeat: no-repeat !important;
		// margin-left: -250px;
	}

	.btnStyle {
		text-align: center;
		margin-top: 15px;
	}

	.div_svg {
		position: relative;
		height: 77%;
		display: flex;
		width: 90%;
		margin: 0 auto;
		overflow: hidden;
		justify-content: center;
		align-items: center;
	}
</style>