<!--用户协议-->
<template>
  <div class="userAgreement">
    <h4 class="agreementh">新田格练字系统用户隐私政策
</h4>
    <div class="agreementContent">
      【前言】<br>
      欢迎使用新田格练字系统提供的服务！新田格练字系统（指新田格练字系统网站、客户端、小程序及新田格练字系统网站、客户端、小程序对应的服务，以下称“新田格练字系统”或简称“我们”）深知个人信息对您的重要性，我们一向庄严承诺保护使用我们的产品和服务之用户（以下统称“用户”或“您”）的个人信息及隐私安全。您在使用新田格练字系统时，我们可能会收集和使用您的相关个人信息。我们希望通过《新田格练字系统隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好的保障您的权益。<br>
      【特别提示】<br>
      本政策适用于新田格练字系统。如本政策与《新田格练字系统用户服务协议》、《新田格练字系统积分服务条款》、《新田格练字系统用户充值购买协议》同类条款之约定存在不一致的，以本政策为准。<br>
      在阅读完本政策后，如您对本政策或与本政策相关的事宜有任何问题，您可通过本政策“如何联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。<br>
      本政策将帮助您了解以下内容：<br>
      临沂学志教育科技有限公司（下称“我们”或“公司”）尊重并保护用户隐私，在您使用新田格练字系统微信公众号、网页、小程序或者APP平台（以下简称“新田格练字系统”）提供的服务时，我们除了按照《新田格练字系统用户服务协议》的约定向您提供服务外，我们还将按照《新田格练字系统用户隐私政策》（以下简称“本隐私政策”）收集、使用您的个人信息。<br>
      请您仔细阅读本隐私政策的全部条款以便您了解新田格练字系统收集、使用您个人信息的目的、范围、方式等。如果您为未成年人，则您应在法定监护人陪同下审阅本隐私政策，经法定监护人审阅并同意后，您方可使用新田格练字系统并向新田格练字系统提供个人信息。如果您或监护人不同意本隐私政策任何内容，您应立即停止使用新田格练字系统产品或服务。当您使用新田格练字系统提供的任一服务时，即表示您已同意我们按照本隐私政策来收集、使用、存储和保护您的相关信息。<br>
      <b>一、适用范围<br></b>
      （1）除某些特定产品和服务外，本隐私政策适用于新田格练字系统提供的所有服务。这些特定产品和服务将适用特定的隐私政策。<br>
      （2）需要特别说明的是，本隐私政策也不适用于其他第三方向您提供的服务。包括但不限于下述情形：<br>
      a)通过我们的产品和服务而接入的第三方服务（包括任何第三方网站）收集的信息，（不包括本政策第五条约定与第三方共享的信息）；<br>
      b)通过在我们的产品和服务中进行运营服务的其他公司或机构所收集的信息（不包括本政策第五条约定与第三方共享的信息）。请你知悉：如果您浏览第三方网站或使用第三方产品、服务时向其提供个人信息，则您的信息应当使用该第三方的隐私政策，我们不承担任何法律责任。<br>
      <b>二、我们收集哪些信息<br></b>
      为了向您提供更好、更优、更个性化的服务，我们将根据您使用的服务收集您的如下信息：（1）您注册或登录的账户信息，例如您注册、登录新田格练字系统账户使用的电话号码，用户自行填写的姓名或昵称、班级、年级、学校、性别、出生日期、身份、入学年份等；<br>
      （2）您自行上传的信息，例如您的发帖、评论、分享，主动填写的信息或您与新田格练字系统客服联系时提供的相关信息等；<br>
      （3）您使用新田格练字系统过程中产生的信息。公司会根据您授予的权限，接收并记录您所使用的设备相关信息，以保障您的账户及交易安全，包括设备属性信息（如IMEI、IMSI、设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备位置信息（如通过GPS、蓝牙或WIFI信号获得的位置信息）、设备连接信息（如IP地址、电信运营商、访问日期和时间等）等；<br>
      （4）您在新田格练字系统购买需要邮寄的产品时或者参加我们有关的市场营销活动时提供的姓名、通信地址、联系方式等；<br>
      （5）新田格练字系统根据您的授权通过合法方式收集的其他个人信息。<br>
      <b>三、我们可能如何收集信息<br></b>
      我们或我们的第三方合作伙伴，可能通过cookies和web beacon收集和使用您的信息，并将该等信息储存为日志信息。我们使用自己的cookies和web beacon，目的是为您提供更个性化的用户体验和服务，并用于以下用途：<br>
      （1）记住您的身份。例如：cookies和web beacon有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息。<br>
      （2）分析您使用我们服务的情况。例如，我们可利用cookies和web beacon来了解您使用我们的服务进行什么活动，或哪些服务最受您的欢迎。<br>
      我们为上述目的使用cookies和web beacon的同时，可能将通过cookies和web我们为上述目的使用cookies和web beacon的同时，可能将通过cookies和web
      beacon收集的非个人身份信息，经统计加工后提供给其他合作伙伴，用于分析用户如何使用我们的服务。
      我们的产品和服务上可能会有其他合作方放置的cookies和web beacon。这些cookies和web beacon可能会收集与您相关的非个人身份信息，以用于分析您如何使用该等产品或服务。这些第三方cookies和web
      beacon收集和使用该等信息，不受本隐私政策约束，而是受相关第三方的隐私政策约束，我们不对此类cookies和web beacon承担责任。
      您可以通过浏览器设置拒绝或管理cookies。但请注意，如果停用cookies或web beacon，您有可能无法享受最佳的服务体验，某些产品或服务可能也无法正常使用。<br>
      <b>四、我们如何使用收集到的信息<br></b>
      收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会把您的信息用于下列用途：<br>
      （1）向您提供您使用的各项产品或服务；<br>
      （2）帮助我们设计新产品或服务，改善现有的产品户或服务；<br>
      （3）在我们提供产品或服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br>
      （4）帮助我们更加了解您使用我们产品和服务的情况，从而针对性的回应您的个性化需求，以便为您提供服务、个性化内容和建议；<br>
      （5）经您许可的其他用途。<br>
      <b>五、我们如何共享或披露信息<br></b>
      （1）我们会以高度的勤勉义务对待您的信息，除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：我们可能将我们按照本隐私政策收集的您的信息提供给我们的关联公司、合作伙伴或第三方服务提供方（比如代表我们发送短信的服务提供商），用作下列用途：<br>
      a)将您的IMEI信息共享至中移互联网有限公司、中国联合网络通信集团有限公司、中国电信集团有限公司、北京容联易通信息技术有限公司用于一键登录验证、验证码接收；<br>
      b)将您的网络状态、定位信息共享至北京百度网讯科技有限公司的百度地图SDK定位，用于您在完善个人资料选择学校时，定位附近的学校，为您提供更优质的服务；<br>
      c)将您的手机状态、网络状态、读取外部存储共享至阿里云计算有限公司的阿里云反馈SDK，用于您反馈问题时的手机、网络状态给出诊断建议，读取外部存储截图提供反馈问题详情；
      d)将您的手机状态、网络状态、读取写入外部储存共享至支付宝(中国)网络技术有限公司的支付宝SDK，用于处理和缓存您的支付订单及支付状态；<br>

      e)将您的手机状态、网络状态、定位信息、读取写入外部储存共享至深圳市腾讯计算机系统有限公司的腾讯验证码SDK，用于向用户多次密码输入错误时出示并校验验证码。<br>
      （2）我们不会非法出售、非法向他人提供您的信息；我们不会将您的该等信息提供给任何第三方，也不会用于任何其他目的和用途，但是以下情形除外：<br>
      a)经您事先同意，向第三方披露；<br>
      b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      c)我们与第三方合作向您提供新田格练字系统平台的相关服务或其他网络服务，且该等第三方同意承担与我们同等的保护用户隐私的责任；<br>
      d)其他根据法律、法规或者政策应进行的披露。<br>
      <b>六、您如何管理自己的信息及账号注销<br></b>
      （1）您可以在“个人中心”->“我的资料”（或点击头像）中查询、更正、修改您的个人信息。<br>
      （2）如果您决定停止使用新田格练字系统的，则您可以对您的账号进行注销。账号注销可以在“新田格练字系统”->“我的”->“个人资料”（或点击头像）->“注销”中所提供的方法进行注销。注销账号之后此账号内的数据无法恢复，包括但不限于账户内的资产信息如果豆、优惠券等；账号信息内容也将全部清除，包含但不限于该账号发布过的文章、评论等。该注销操作不能撤销，请您务必谨慎申请。<br>
      <b>七、我们如何存储您的信息<br></b>
      我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，并按照中国中华人民共和国法律法规、部门规章等规定的存储期限存储您的个人信息，存储期限为您使用新田格练字系统的期限或者向新田格练字系统发出删除您的个人信息之前，当您要求删除该存储信息或者决定停止使用新田格练字系统注销账号之时，我们将及时删除您的个人信息。<br>
      <b>八、我们如何保护您的信息<br></b>
      （1）我们非常重视您的个人信息安全。我们成立了专门的安全团队，努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您个人信息的员工或外包人员也采取了严格管理，包括但不限于采取信息访问权限控制、与接触个人信息的人员签署保密协议、监控该等人员的操作情况等措施。<br>
      （2）尽管有上述第（1）款的约定，但是请您理解并明白，由于存在各种各样无法预知或现有技术无法防御的恶意手段，即便我们已经尽力采取必要的保护措施，您的信息仍有可能被泄露、毁损或灭失。如您发现您的账号、密码因任何原因已经或者将要泄露时，您应当立即跟我们联系，以便我们可以及时采取应对措施防止或减少您的相关损失。<br>
      （3）如果我们发生企业合并、分立等情形的，则您的信息将由届时存续的企业主体继续履行保护义务，并由我们以公告、短信或其他书面方式通知您；如果我们的产品、服务停止运营的，则我们将及时停止继续收集您的信息，并以公告、短信等方式通知您，届时我们将对您的信息进行及时删除处理。<br>
      <b>九、本政策的修订<br></b>
      我们可不定期修改本隐私政策，变更后的隐私政策将在修订生效前通过新田格练字系统平台公告或以其他适当方式通知您。该等情况下，若您继续使用我们的服务，即表示同意受经修订的隐私政策的约束。<br>
      <b>十、争议管辖<br></b>

      1.本隐私政策的签订、生效、履行、争议的解决均适用中华人民共和国法律。<br>
      2.有关本协议的争议应通过友好协商解决，如果协商不成，该争议将提交公司所在地有管辖权的法院诉讼解决。<br>

      如您有任何疑问或投诉、举报，您可以通过下述方式联系我们。<br>

      临沂学志教育科技有限公司<br>

      地址：山东省临沂市兰山区大正商务办公四楼<br>

      邮箱：chinaxuezhi@163.com<br>

      联系电话：0539-7756567<br>

        生效日期：2021年1月1日


    </div>
  </div>
</template>

<script>
export default {
  name: "userPolicy"
}
</script>

<style scoped>
.userAgreement {
  width: 90%;
  margin: 20px auto;
  text-align: center;
  line-height:30px;
}
.agreementh{
  font-size: 20px;
}

.agreementContent{
  text-align: left;
  font-size: 14px;
}
</style>
