const user = [

    {
        path: '/download',
        name: 'download',
        component: () => import('../views/download'),
        meta: {
            title: '下载页面',
            close_head: true

        }
    },
    {
        path: '/aboutOur',
        name: 'aboutOur',
        component: () => import('../views/aboutOur.vue'),
        meta: {
            title: '关于我们',
            requireAuth: true,
        },
    },
    {
        path: '/explainChinese',
        name: 'explainChinese',
        component: () => import('../views/explainChinese.vue'),
        meta: {
            close_head: true,
            requireAuth: true,
            bodycolor: '#50ae81',
        },
    },
    {
        path: '/idiomDetail',
        name: 'idiomDetail',
        component: () => import('../views/idiomDetail.vue'),
        meta: {
            title: "组词成语",
            requireAuth: true,
            bodycolor: '#50ae81',
        },
    },
]
export default user;
