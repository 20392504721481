/**
 * CSS3 答题卡翻页效果 jQuery Transit
 * @authors Candice <286556658@qq.com>
 * @date    2016-9-27 15:30:18
 * @version 1.0.8
 */
import $ from 'jquery'
$.fn.answerSheet = function () {
    return $(this).each(function () {
        var obj = $(this).find('.card_cont');
        var _length = obj.length, _b = _length - 1;
        for (var a = 1; a <= _length; a++) {
            obj.eq(_b).css({'z-index': a});
            _b -= 1;
        }
        $(this).show();
    });
};
