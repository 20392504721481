const isDevelopment = process.env.NODE_ENV == 'production'
// const isDevelopment = process.env.NODE_ENV !== 'production'
const base = {
    dev: isDevelopment ? "https://xintiange.cn/api/" : 'https://xintiange.cn/api/',
    //dev: 'http://127.0.0.1:7777/',
    // ass: 'https://chinaxuezhi.com/static/'
    ass: 'http://oss.newtiange.com/',
    // ass2: 'https://chinaxuezhi.cn/cz/',
    // oss_ass:'https://chinaxuezhi.com/aaaa/'
}

export default base;