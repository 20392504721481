import $ from 'jquery'

function LevelUp(config) {
    this.init(config)
}

LevelUp.prototype = {
    start: 0,
    value: 0,
    showThousands: false,
    thousandSep: ",",
    incrementer: {bold: true, color: null, "class": null},
    decrementer: {bold: true, color: null, "class": null},
    constructor: LevelUp,
    el: null,
    init: function (config) {
        $.extend(this, config)
    },
    getValue: function () {
        return this.value
    },
    setValue: function (newValue) {
        this.value = newValue
    },
    dataName: "levelup",
    animateUpdate: function (update) {
        var instance = this;
        var $tw = instance.el;
        var p = $tw.position();
        var h = $tw.height();
        var w = $tw.width();
        var nl = p.left + w;
        var nt;
        var s;
        var $x;
        if (update >= 0) {
            if (instance.showThousands) {
                update = numberWithCommas(update, instance.thousandSep)
            }
            nt = p.top - h;
            s = getStyle(nt, nl, instance.incrementer);
            $x = $("<span>", {text: "+" + update, style: s});
            if (instance.incrementer.class) {
                $x.addClass(instance.incrementer.class)
            }
        } else {
            if (instance.showThousands) {
                update = numberWithCommas(update, instance.thousandSep)
            }
            nt = p.top;
            s = getStyle(nt, nl, instance.decrementer);
            $x = $("<span>", {text: update, style: s});
            if (instance.decrementer.class) {
                $x.addClass(instance.decrementer.class)
            }
        }
        $tw.parent().append($x);
        var realWidth = $x.width();
        var newerLeft = nl - realWidth;
        $x.css("left", newerLeft + "px");
        setTimeout(function () {
            $x.css(trans);
            var e = nt + h;
            $x.css("top", e + "px");
            setTimeout(function () {
                $x.remove();
                var value = $tw.data(instance.dataName).getValue();
                if (instance.showThousands) {
                    value = numberWithCommas(value, instance.thousandSep)
                }
                $tw.text(value)
            }, 250)
        }, 100)
    }
};
var trans = {
    "-webkit-transition": "all 0.25s",
    "-moz-transition": "all 0.25s",
    "-o-transition": "all 0.25s",
    transition: "all 0.25s"
};

function numberWithCommas(x, sep) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep)
}

function getStyle(top, left, settings) {
    var styles = ["position:absolute", "top:" + top + "px", "left:" + left + "px", "z-index:999"];
    if (settings.bold) {
        styles.push("font-weight:bold")
    }
    if (settings.color) {
        styles.push("color:" + settings.color)
    }
    return styles.join(";")
}

$.fn.levelup = function (configOrCommand, commandArgument) {
    var dataName = LevelUp.prototype.dataName;
    if (typeof configOrCommand == "string") {
        commandArgument = parseInt(commandArgument);
        if (configOrCommand === "increment") {
            return this.each(function () {
                var instance = $(this).data(dataName);
                instance.setValue(instance.getValue() + commandArgument);
                instance.animateUpdate(commandArgument)
            })
        } else if (configOrCommand === "decrement") {
            return this.each(function () {
                var instance = $(this).data(dataName);
                instance.setValue(instance.getValue() - commandArgument);
                instance.animateUpdate(-1 * commandArgument)
            })
        } else if (configOrCommand === "reset") {
            return this.each(function () {
                var instance = $(this).data(dataName);
                instance.setValue(instance.start);
                instance.el.text(instance.start)
            })
        } else if (configOrCommand === "get") {
            var instance = $(this).data(dataName);
            return instance.getValue()
        }
    }
    return this.each(function () {
        var el = $(this), instance = el.data(dataName),
            config = $.isPlainObject(configOrCommand) ? configOrCommand : {};
        if (instance) {
            instance.init(config)
        } else {
            var initialConfig = $.extend({}, el.data());
            config = $.extend(initialConfig, config);
            config.el = el;
            if (config.incrementer) {
                var inc = $.extend(true, {}, LevelUp.prototype.incrementer, config.incrementer);
                $.extend(config.incrementer, inc)
            }
            if (config.decrementer) {
                var dec = $.extend(true, {}, LevelUp.prototype.decrementer, config.decrementer);
                $.extend(config.decrementer, dec)
            }
            instance = new LevelUp(config);
            el.data(dataName, instance);
            if (instance.showThousands) {
                instance.start = numberWithCommas(instance.start, instance.thousandSep)
            }
            el.text(instance.start)
        }
    })
}
