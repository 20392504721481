<template>
	<div class="bodyStyle">
		<div class="main_title">新田格高效练字系统</div>
		<div style="display: none;opacity: 0;" class="card border-0 shadow card--welcome" id="welcome">
		</div>
		<div class="main_con">

			<div style="width: 100%;">
				<img class="main_con_gun" src="../assets/image/login_gun.png">
				<div class="form_con">
					<div class="form_con_title">
						<div class="form_con_title_l">
							◆
							<span class="line"></span>
						</div>
						<text class="wel_login">欢迎登录</text>
						<div class="form_con_title_r">
							<span class="line"></span> ◆
						</div>
					</div>
					<div class="form-group border_input align-center"
						style="border-radius: 5px;padding:12px 10px;justify-content: space-between;border: 1px solid #E4E7ED;">
						<input class="" type="text" placeholder="学校编号" v-model="phoneNumber" required="required" />
						<img @click="showKeyboard" src="../assets/image/jianpan.png" style="width:35px;height:30px"
							alt="">
					</div>
					<!-- // inputStr-输入的内容
					// showKeyBoard-是否展示数据键盘 -->
					<!-- <keyboard v-model="phoneNumber" v-show="showKeyBoard" /> -->

					<div class="form-group align-center"
						style="border-radius: 5px;padding:12px 10px;justify-content: space-between;border: 1px solid #E4E7ED;">
						<input class="" type="password" placeholder="请输入密码" v-model="passWord" required="required" />
						<img @click="showKeyboard" src="../assets/image/jianpan.png" style="width:35px;height:30px"
							alt="">
					</div>

					<div class="form-group" style="justify-content: space-between">
						<div>
							<van-checkbox v-model="rememberPsw" shape="square" style="display: inline-block"
								icon-size="15px" checked-color="#02427D"></van-checkbox>
							<span class="userXieyi userLogin">记住密码</span>
						</div>
						<div style="display: flex;align-items: center;cursor: pointer" @click="changeIME">
							<!-- <img src="../assets/image/diqiu.png" style="width: 20px;" alt=""> -->
							<div class="write_type" v-if="key==0x4090409">英</div>
              <div class="write_type" v-if="key==0x8040804">中</div>
							<span style="color: #02427D;margin-left: 5px;">切换输入法</span>
						</div>
					</div>
					<img v-show="checked" class="login_btn" src="../assets/image/login_true.png" @click="login">
					<img v-show="!checked" class="login_btn" src="../assets/image/login_false.png">
					<img class="login_btn" src="../assets/image/close3.png" style="margin:16px auto 20px auto"
						@click="close_system">
					<div class="form-group" style="font-size: 12px;padding-bottom: 25px">
						<van-checkbox v-model="checked" shape="round" style="display: inline-block" icon-size="15px"
							checked-color="#02427D"></van-checkbox>
						<span style="margin-left: 5px">我已阅读并同意</span>
						<span class="userXieyi userLogin">《隐私协议》</span>和
						<span class="userXieyi userLogin"
							@click="showPopup = true;userAgreementType='agreement'">《用户服务协议》</span>
					</div>
				</div>
				<img class="main_con_gun2" src="../assets/image/login_gun.png">
			</div>

		</div>
		<van-popup v-model:show="showPopup" :style="{ height: '95%', width:'100%' }" closeable position="bottom">
			<userAgreement v-if="userAgreementType==='agreement'"></userAgreement>
			<userPolicy v-if="userAgreementType==='policy'"></userPolicy>
		</van-popup>
	</div>
</template>

<script>
	// import Keyboard from "vue-touch-keyboard"
	// import Keyboard from "@/components/board"
	// import "vue-touch-keyboard/dist/vue-touch-keyboard.css"
	import {
		ref
	} from "vue"
	import {
		Notify
	} from "vant"
	import userAgreement from "@/components/userAgreement";
	import userPolicy from "@/components/userPolicy";
	import {
		invoke
	} from "@tauri-apps/api";
	import {
		ipc
	} from "@/utils/ipcRenderer";
	import {
		ipcApiRoute
	} from "@/request/ipcApi";

	export default {
		name: 'loginreg',
		components: {
			userAgreement,
			userPolicy,
			// Keyboard
		},
		async mounted() {
			let doc = document.getElementById('welcome');
			doc.classList.add('is-show');
      this.key = await ipc.invoke(ipcApiRoute.getKey)
		},
		setup() {
			let phoneNumber = ref(""); // 手机号
			const checked = ref(false);
			const rememberPsw = ref(true);

			return {
				phoneNumber,
				checked,
				rememberPsw
			}
		},
		inject: ['reload'],
		data() {
			return {
				passWord: '', // 密码
				isShow: 'login',
				showPopup: false,
				startY: 0,
				moveY: 0,
				userAgreementType: "",
				checkCodeGet: "",
        key:0x0,
				options: {
					alwaysOpen: true
				},
				// showKeyBoard: true
			}
		},

		methods: {
			showKeyboard() {
				// console.log(this.$refs)
				this.osk()
				// this.$refs.keyboard.open()
			},
			close_system() {
				ipc.invoke(ipcApiRoute.close)
				invoke("exit")
			},
			osk() {
				ipc.invoke(ipcApiRoute.osk)
				invoke("osk")
			},
			async changeIME() {
				await ipc.invoke(ipcApiRoute.changeIME)
        this.key = await ipc.invoke(ipcApiRoute.getKey)
        // console.log(this.key)
        // if ( this.key == 0x4090409){
        //   Notify({
        //     type: 'success',
        //     message: "成功切换为英文"
        //   });
        // }else if( this.key == 0x8040804){
        //   Notify({
        //     type: 'success',
        //     message: "成功切换为中文"
        //   });
        // }

			},
			// 登录方法
			login() {

				if (!this.checked) {
					Notify({
						type: 'warning',
						message: "请勾选用户协议"
					});
					return false;
				}
				this.$api.post('auth/auth', {
					phone: this.phoneNumber,
					password: this.passWord
				}).then((res) => {
					localStorage.setItem('username', res.data['username'])
					localStorage.setItem('phone', res.data['phone'])
					this.$store.commit("set_level", res.data['level'])
					// localStorage.setItem('level', res.data['level'])
					if (this.rememberPsw === true) {
						localStorage.setItem("flag_auth", 1)
						localStorage.setItem('auth', res.data['auth'])
					} else {
						sessionStorage.setItem('auth', res.data['auth'])
					}
				}).then(() => {
					this.$router.replace('/')
					this.reload()
				}).catch(() => {
					Notify({
						type: 'danger',
						message: '登陆失败'
					})
				})
			},

			on_btn_click(e) {
				// 重新进入页面清空所有数据
				this.phoneNumber = ''; // 手机号
				this.passWord = ''; // 密码
				this.isShow = 'login';
				this.checked = false;
				let nextID = e.currentTarget.getAttribute('data-target');
				this.bg_change(nextID);
				this.view_change();
				return false;
			},
			bg_change(next) {
				document.body.className = '';
				document.body.classList.add('is-' + next);
			},
			view_change() {
				this.isShow = 'login';
			},
		}
	}
</script>
<style scoped src="../assets/css/login.css"></style>
<style scoped>
	.write_type{
		border-radius: 5px;
		padding: 3px 14px;
		background-color: #EBEEF5;
		color: #02427D;
		font-weight: 600;
	}
	.userXieyi {
		display: inline-block;
		margin-left: 2px;
		cursor: pointer;
	}

	.userReg {
		color: #ea87aa;
	}

	.userLogin {
		font-weight: 600;
		color: #02427D;
	}

	.getBtn {
		display: inline-block;
		bottom: 0;
		margin-left: 3px;
		text-align: center;
	}

	.verifInput {
		width: 9rem;
		display: inline-block;
	}

	.card {
		background-color: white;
	}

	.noClicked {
		background-color: #959698;
		color: #fff !important;
		width: 10em;
		transition: all .4s;
		border-radius: 20px;
		border: 2px solid transparent;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		padding: .375rem .75rem;
		font-size: 1rem;
		line-height: 1.5;
	}

	#loginanreg {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;

	}

	.close_icon {
		width: 135px;
		height: 50px;
	}
</style>