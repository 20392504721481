<template>
	<div class="numberStyle_div">
		<div class="main">
			<div class="main_l">
				<div class="main_l_title">{{index_title}}</div>
				<div class="main_l_list">
					<div @click="Checkzi(item)" :class="hanzi==item?'checkhanzi hanzistyle':'hanzistyle'" :key="index"
						v-for="(item,index) in $store.state.zierData">{{item}}</div>
				</div>
				<!-- <Yswiper :chinesecharactersarr="$store.state.zierData" @changehanzi="changehanzi"></Yswiper> -->

			</div>
			<div class="main_middle">
				<img class="guagou" src="../assets/image/guagou.png" alt="">
				<img class="guagou" src="../assets/image/guagou.png" alt="">
			</div>
			<div class="main_r">
				<div class="main_r_title">【{{hanzi}}】字解释</div>
				<div class="main_flex">
					<!-- <videoTemp class="video" :video="vd"></videoTemp> -->
					<video id="vv" ref="zi_video" controlslist="nodownload" autoplay webkit-playsinline="true"
						playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5"
						x5-video-player-fullscreen="true" x5-video-orientation="portraint" class="video" controls
						:src="vd" @contextmenu.prevent>
					</video>
					<div>
						<img @click="openShiyi()" src="../assets/image/jiandanshiyi.png" alt="">
						<img @click="openChengyu()" src="../assets/image/zucichengyu.png" alt="">
						<img @click="openShuowen()" src="../assets/image/shuowen.png" alt="">
						<img @click="openGame()" src="../assets/image/goGame.png" alt="">
						<img class="" @click="openvideo()" src="../assets/image/quanping.png" alt="">
					</div>
				</div>
				<div class="main_r_footer">
					<div class="align-center main_r_f_title" @click="handleSpeak()">
						说文解字
						<img src="../assets/image/laba.png" class="laba" alt="">
					</div>

					<div class="align-center main_r_info">
						<div>
							拼音：{{ simpleExp["pinyin"] }};
						</div>
						<div>笔画：{{ simpleExp["bihuashu"] }};</div>
						<div>部首：{{simpleExp["bushou"]}}</div>

					</div>
				</div>
			</div>
		</div>
		<van-popup round overlay-class="pop_style" v-model:show="shiyishow" :style="pop_style">
			<div class="pop_con">
				<div class="pop_con_tab">
					<img v-if="current==0" @click="changeCurrent(0)" class="pop_con_tabimg"
						src="../assets/image/shiyi.png" alt="">
					<img v-if="current==1" @click="changeCurrent(0)" class="pop_con_tabimg"
						src="../assets/image/shiyi2.png" alt="">
					<img v-if="current==0" @click="changeCurrent(1)" class="pop_con_tabimg"
						src="../assets/image/chengyu.png" alt="">
					<img v-if="current==1" @click="changeCurrent(1)" class="pop_con_tabimg"
						src="../assets/image/chengyu2.png" alt="">
				</div>
				<div class="pop_info">
					<div v-if="current==0">
						{{ simpleExp["jianshi"] }}
					</div>
					<div class="shiyi-bottom" v-else>
						<span style="color: #285b35; font-size: 10px; margin: 10px">注：可以点击成语查看详情</span><br>
						<div class="aaa">
							<span class="idiomTo" v-for="(item, index) of idiomList" :key="index"
								@click="clickIdiom(item)">
								<a href="javascript:">{{ item.cyname }}</a></span>
						</div>
					</div>
				</div>
				<div style="height:40px"></div>
			</div>
		</van-popup>
		<van-popup round overlay-class="pop_style" v-model:show="chengyushow" :style="pop_style">
			<div class="pop_con">
				<div class="idiomDetail">
					<div class="idiomDetailTop">
						<div class="idiomTitle">{{ idiom }}</div>
						<table class="idiomTable">
							<tr v-for="(item, index) in idiomTitleList" :key="index"
								v-show="idiomDetailList[item]!==null">
								<td class="spanLeft">{{ item }}</td>
								<td class="classRight">{{ idiomDetailList[item] }}</td>
							</tr>
						</table>
					</div>
					<div style="height: 40px;"></div>
				</div>

			</div>

		</van-popup>
		<van-popup @close="CloseShuowen" round overlay-class="pop_style" v-model:show="shuowenshow" :style="pop_style">
			<div class="pop_con">
				<div class="table_title">说文解字</div>
				<div class="explainChineseBottom">
					<div class="itemDiv">
						<div class="explainSpanTop">"{{ hanzi }}"的字形演变</div>

						<div class="table">
							<div class="table_item" v-for="(item, index) of explainInfo.explainList" :key="index">
								<div class="table_text">{{item.text}}</div>
								<div class="table_bg">
									<van-image :src="item.img" width="100%" height="100%">
										<template v-slot:error>缺</template>
									</van-image>
								</div>
							</div>
						</div>
						<!-- 	<table style="margin: 10px auto">
							<tr>
								<td v-for="(item, index) of explainInfo.explainList" :key="index">{{ item.text }}</td>
							</tr>
							<tr>
								<td class="td2" v-for="(item, index) of explainInfo.explainList" :key="index">
									<div>
										<van-image :src="item.img" width="100%" height="100%">
											<template v-slot:error>缺</template>
										</van-image>
									</div>
								</td>
							</tr>
						</table> -->
					</div>
					<div style="margin-top: 10px" class="itemDiv">
						<div class="explainSpanTop">"{{ hanzi }}"的字源解说</div>

						<div v-html="text_swjz">
						</div>

					</div>
				</div>
			</div>
		</van-popup>
		<div class="align-center fixed">


			<img @click="shiyishow=false" src="../assets/image/close_b.png" class="close_icon" alt=""
				v-if="shiyishow==true">
			<img @click="chengyushow=false" src="../assets/image/close_b.png" class="close_icon" alt=""
				v-if="chengyushow==true">
			<img @click="CloseShuowen()" src="../assets/image/close_b.png" class="close_icon" alt=""
				v-if="shuowenshow==true">
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	// import Yswiper from "@/components/Yswiper";
	import Footer from "@/components/Footer";
	import {
		useStore
	} from "vuex";
	import HanziWriter from "hanzi-writer";
	import audio from "@/audio/audio";
	import base from "@/request/base";
	// import videoTemp from "../components/videoTemp";
	import {
		defineComponent,
		ref
	} from "vue";

	const synth = window.speechSynthesis;
	const msg = new SpeechSynthesisUtterance();

	export default defineComponent({
		name: "video",
		components: {
			// videoTemp,
			Footer
			// NewSvg,
			// Yswiper
		},
		setup() {
			const store = useStore();

			let updown = ref({
				cls: "",
				hz: "",
				num: 0,
				maxnum: 0,
			});
			const up_character = () => {
				//console.log(hanzi);
				let content = document.getElementById("grid-background-target");
				if (content.childNodes.length === 1 || updown.value.cls === "anm") {
					return false;
				} else {
					content.removeChild(content.lastChild);
					content.removeChild(content.lastChild);
					updown.value.num -= 1;
				}
			};

			const down_character = (cls, hanzi) => {
				let content = document.getElementById("grid-background-target");
				if (
					updown.value.cls === "down" &&
					updown.value.hz === hanzi &&
					updown.value.num !== updown.value.maxnum
				) {
					HanziWriter.create(
						content,
						hanzi,
						store.state.updownstroke
					).animateStroke(updown.value.num);
					updown.value.num += 1;
				} else {
					while (content.childNodes.length !== 1) {
						content.removeChild(content.childNodes[1]);
					}
					HanziWriter.loadCharacterData(hanzi).then((r) => {
						updown.value = {
							cls: "down",
							hz: hanzi,
							maxnum: r.strokes.length,
							num: 1,
						};
					});
					HanziWriter.create(
						content,
						hanzi,
						store.state.updownstroke
					).animateStroke(0);
				}
			};

			const characterAnimation = (options_can, hanzi) => {
				audio(0);
				let content = document.getElementById("grid-background-target");
				while (content.childNodes.length !== 1) {
					content.removeChild(content.childNodes[1]);
				}
				// console.log(flag.value);
				updown.value.cls = "anm";
				switch (options_can) {
					case "cl": // 笔顺动画
						HanziWriter.create(content, hanzi, store.state.stroke);
						break;
					case "strokeOrderAnimation": // 笔顺动画
						HanziWriter.create(
							content,
							hanzi,
							store.state.strokeOrderAnimation
						).animateCharacter();
						break;
					case "writerPractice": // 书写练习
						quiz(HanziWriter.create(content, hanzi, store.state.writerPractice));

						break;
					case "silentWriting": // 默写测评
						quiz(HanziWriter.create(content, hanzi, store.state.silentWriting));
						break;
				}
			};
			const quiz = (writer) => {
				writer.quiz({
					onMistake: () => {
						// 书写错误的方法
						/*audio(0);*/
					},
					onCorrectStroke: () => {
						// 书写正确的方法
					},
					onComplete: () => {
						// 书写完成
						audio(0);
					},
				});
			};
			const img = `${base.ass}image/sc.svg`;
			const idiom = sessionStorage.getItem("idiom");
			// const currentChinese = localStorage.getItem("explainChineseTitle");
			// const title = '说文解字';
			// const router = useRouter();
			// const goBack = () => window.history.length > 1 ? router.go(-1) : router.push('/');

			//`${base.ass}image/explainChinese/rili.png`

			return {
				characterAnimation,
				img,
				idiom,
				down_character,
				up_character,
				// currentChinese,
				// goBack,
				// title,
				// explainInfo
			};


		},
		data() {
			return {
				hanzi: this.$store.state.zierData[0],
				Switchingfields: true,
				shixun: true,
				nowhanzi: this.$store.state.zierData[0],
				simpleExp: [],
				vd: '',
				shuowenshow: false,
				idiomList: [],
				idiomTitleList: ['成语解释', '成语出处', '成语繁体', '常用程度', '成语字数', '感情色彩', '成语用法', '成语结构',
					'成语年代', '成语谜语', '成语故事', '成语辨形', '成语辨析', '成语歇后语', '成语正音', '近义词', '反义词',
					'成语例子', /*'英语翻译', '成语简拼', '成语注音','日语翻译','俄语翻译','其他翻译',*/
				],
				idiomDetailList: [],
				shiyishow: false,
				chengyushow: false,
				pop_style: {
					width: '60%'
				},
				current: 0,
				text_swjz: "",
				explainInfo: {},
				showImgText: true, // 图像没有的时候展示字
				chineseArr: this.$store.state.zierData // 存放所有的字当前课程的 add by s
			};
		},
		mounted() {
			// const myVideo = this.$refs.zi_video
			// this.$nextTick(() => {
			// 	myVideo.addEventListener('ended', this._unbindHandlerFull)
			// })

			this.getInfo()
			this.getData()
			this.Checkzi(this.chineseArr[this.$store.state.cont_zi])
			this.changeHanzi(this.chineseArr[this.$store.state.cont_zi])
			this.getHanzi()
			console.log(this.hanzi)
			this.getZi()
		},
		computed: {
			index_title() {
				const sa = this.$route.meta.title
				if (sa === 'project') {
					var a = this.$store.state.kmcData
					return a.slice(a.indexOf('.') + 1, a.length)
				} else {
					return sa
				}
			},
		},
		methods: {
			openGame() {
				this.$router.push("game");
			},
			getZi() {
				const explainInfo2 = {};
				/*
				    explainInfo.explainText = "“处”是“”和“”的异体字。，金文（人，归家者）（几，凳子），表示坐在凳子休息，享受居家时光。凳子上加一横指事符号，表示坐在凳子上休息。篆文“”：（夂，倒写的“止”）（几，凳子），表示驻足家中，坐几休息。造字本义：动词，驻足家中，坐几休息。由于“处”（居家休息）的篆文字形与“處”（实施虎噬酷刑）的篆文下部字形完全相同，古人误将本义表示“居家休息”的“处”，与本义表示“实施虎噬酷刑”的“處” * 相混用。隶化后楷书取金文字形“”中的“单人旁”，写成“卜”形；取篆文字形“”中的“夂”，写成。古籍多以“處”代替“处”；而“”和“”偏废。《汉字简化方案》用“处”合并代替“處”。"
				*/
				explainInfo2.explainList = [{
						text: "甲骨文",
						img: `${base.ass}image/swjz/image/jiaguewn/${this.hanzi}.svg`
					}, {
						text: "金文",
						img: `${base.ass}image/swjz/image/jinwen/${this.hanzi}.svg`
					},
					{
						text: "楚系簡帛",
						img: `${base.ass}image/swjz/image/chuwenzi/${this.hanzi}.svg`
					},
					{
						text: "小篆",
						img: `${base.ass}image/swjz/image/xiaozhuan/${this.hanzi}.svg`
					},
					{
						text: "秦系簡牘",
						img: `${base.ass}image/swjz/image/qinwenzi/${this.hanzi}.svg`
					},
					{
						text: "楷書",
						img: `${base.ass}image/swjz/image/kai/${this.hanzi}.svg`
					}
				];
				this.explainInfo = explainInfo2
				console.log(this.explainInfo)
			},
			getHanzi() {
				this.$api.post("api/swjz", {
					zi: this.hanzi
				}).then((res) => {
					this.text_swjz = res.data[0]["text"]
						.replace(`清代陈昌治刻本【说文解字】`, `◉清代陈昌治刻本【说文解字】<br>`)
						.replace(`${this.hanzi}说文解字白话版`,
							`<br>◉【${this.hanzi}】说文解字白话版<br>`)
						.replace("清代段玉裁【說文解字注】", "<br>◉清代段玉裁【說文解字注】<br>")
				}).catch(() => {})
			},
			openShuowen() {
				// localStorage.setItem("explainChineseTitle", this.hanzi);
				this.getZi()
				this.getHanzi()
				this.shuowenshow = true

			},
			CloseShuowen() {
				localStorage.removeItem("explainChineseTitle")
				this.shuowenshow = false
			},
			// unbind
			_unbindHandlerFull() {
				// console.log('播放结束')
				//檢查瀏覽器是否處於全屏
				if (
					this.invokeFieldOrMethod(document, 'FullScreen') ||
					this.invokeFieldOrMethod(document, 'IsFullScreen') ||
					document.IsFullScreen
				) {
					this.exitFullscreen()
				}
			},
			// 反射调用
			invokeFieldOrMethod(element, method) {
				var usablePrefixMethod;
				['webkit', 'moz', 'ms', 'o', ''].forEach(function(prefix) {
					if (usablePrefixMethod) return
					if (prefix === '') {
						// 无前缀，方法首字母小写
						method = method.slice(0, 1).toLowerCase() + method.slice(1)
					}
					var typePrefixMethod = typeof element[prefix + method]
					if (typePrefixMethod + '' !== 'undefined') {
						if (typePrefixMethod === 'function') {
							usablePrefixMethod = element[prefix + method]()
						} else {
							usablePrefixMethod = element[prefix + method]
						}
					}
				})

				return usablePrefixMethod
			},
			// 退出全屏
			exitFullscreen() {
				if (document.exitFullscreen) {
					document.exitFullscreen()
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen()
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen()
				} else if (document.oRequestFullscreen) {
					document.oCancelFullScreen()
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen()
				} else {
					var docHtml = document.documentElement
					var docBody = document.body
					var videobox = document.getElementById('videobox')
					docHtml.style.cssText = ''
					docBody.style.cssText = ''
					videobox.style.cssText = ''
					document.IsFullScreen = false
				}
			},


			openvideo() {
				var a = document.getElementById('vv')
				console.log(a.requestFullscreen)
				if (a.requestFullscreen) {
					a.requestFullscreen()
				}
			},
			getData() {
				this.$api.post('api/cy', {
					cyname: this.idiom,
				}).then((res) => {
					// console.log(res.data[0]);
					// console.log(1)
					this.idiomDetailList = res.data[0];
				})
			},
			getInfo() {
				this.$api.post('treatment', {
					zi: this.hanzi,
				}).then((res) => {

					this.simpleExp = res.data[0]
				})
			},
			openShiyi() {
				this.current = 0
				this.shiyishow = true
			},
			openChengyu() {
				this.current = 1
				this.shiyishow = true
			},
			changeCurrent(type) {
				this.current = type
			},
			handleSpeak() {
				msg.text = this.hanzi; // 文字内容: 小朋友，你是否有很多问号
				msg.lang = "zh-CN"; // 使用的语言:中文
				msg.volume = 8; // 声音音量：1
				msg.rate = 0.2; // 语速：1
				msg.pitch = 4; // 音高：1
				synth.speak(msg); // 播放
			},

			// 语音停止
			handleStop(e) {
				msg.text = e;
				msg.lang = "zh-CN";
				synth.cancel(msg);
			},
			showPopup() {
				this.shixun = this.shixun !== true;
				this.$audio(0);
			},
			stopshixun() {
				this.shixun = true;
			},
			changeHanzi(element) {
				this.hanzi = element;
				if (this.$store.state.level !== 1) {
					this.vd = `${this.$base.ass}media-finish/${element}.mp4`;
				} else {
					this.vd = `${this.$base.ass}media/cz168/${element}p.mp4`;
				}

				this.$api.post('treatment', {
					zi: element,
				}).then((res) => {

					this.simpleExp = res.data[0]
				})
				// 查询成语
				this.$api.post("api/treatment_characters", {
					zi: element,
				}).then((res) => this.idiomList = res.data)
			},
			Checkzi(ele) {
				this.nowhanzi = ele
				this.stopshixun();
				this.hanzi = ele;
				this.getInfo()
				this.changeHanzi(ele)

				console.log(this.hanzi)
				// this.$refs.shixunref.style.background = `url('${this.$base.ass}yaodian/${ele}.svg')`;
				// this.$refs.shixunref.style.backgroundSize = "482.79px 482.79px";
				// this.characterAnimation("cl", ele);
			},
			clickIdiom(item) {
				console.log(item)
				sessionStorage.setItem("idiom", item.cyname);
				this.idiom = item.cyname
				this.shiyishow = false
				this.chengyushow = true
				this.getData()
			},
			switchingTG() {
				//if 简化
				this.Switchingfields = this.Switchingfields !== true;
				// this.showSound("../assets/sound/qiehuan.mp3")
			},
		},
	});
</script>
<style>
	/deep/.videoDiv {
		border-radius: 20px !important;
	}

	div::-webkit-scrollbar {
		width: 6px;
		height: 40%;
	}

	div::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		opacity: 0.2;
		background-color: #02427D !important;
		/* background: fade(#02427D, 100%); */
	}

	div::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: fade(#02427D, 100%);
	}

</style>
<style lang="scss" scoped>
	.table_title {
		font-size: 33px;
		text-align: center;
		margin: 30px 0 15px 0;
		font-family: 'cn';
		color: #02427D;
	}

	.table {
		display: flex;
		align-items: center;
		justify-content: space-between;



		.table_item {
			width: 15%;

			.table_text {
				background: #02427D;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				font-size: 25px;
				font-family: 'al';
			}

			.table_bg {
				background-repeat: no-repeat;
				background-size: 100% 100%;
				height: 120px;
				background-image: url('../assets/image/tiangelan.png')
			}
		}
	}

	@import "../assets/css/mian.css";



	@font-face {

		font-family: 'kt';
		src: url("@/assets/fonts/楷体_GB2312.ttf");
	}

	@font-face {
		font-family: 'huiti';
		src: url('@/assets/font/AlibabaPuHuiTi-2-55-Regular.ttf');
	}

	.numberStyle_div {
		height: 100%;
		height: 100vh;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transition: background 3s ease;
		background-position: 50% 50%;
		padding: 30px;
		background-image: url('../assets/image/home_bg.jpg');
	}

	.main {
		display: flex;
		align-items: center;
		margin: 30px;
		height: 87vh;
		justify-content: center;
		background-position: 50% 50%;
		background-image: url('../assets/image/blue_rgb.png');
		background-size: 100% 100%;
		padding: 25px;

		.main_l {
			width: 30%;
			height: 82vh;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: background 3s ease;
			background-position: 50% 50%;
			padding: 30px;
			text-align: center;
			margin-right: -50px;
			background-image: url('../assets/image/lianzi_lbg.png');

			.main_l_title {
				font-size: 40px;
				color: #02427D;
				font-weight: 600;
				font-family: 'cn';
			}

			.main_l_list {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin: 0 20px;
				overflow-y: scroll;
				height: 65vh;
			}

			.checkhanzi {
				color: #02427D !important;
				background-image: url('../assets/image/tiangelan.png') !important;
			}

			.hanzistyle {
				margin: 10px 0;
				text-align: center;
				// line-height: 130px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 78px;
				color: #909399;
				width: 45%;
				font-family: 'kt';
				height: 25%;
				background-size: 100% 100%;
				background-image: url('../assets/image/tiange.png');
			}
		}

		.main_r {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-left: -50px;
			width: 70%;
			height: 82vh;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: background 3s ease;
			background-position: 50% 50%;
			padding: 30px 50px;
			background-image: url('../assets/image/hanzi_r_bg.png');

			.main_r_title {
				font-size: 35px;
				color: #02427D;
				font-weight: 600;
			}

			.main_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 20px 0;
				padding: 0 20px;
				height: 430px;

				img {
					width: 130px;
					height: 50px;
					margin: 25px 0;
				}
			}

			.video {
				border-radius: 14px;
				margin: 0;
				height: 390px;
				// width: 630px;
				object-fit: fill;
			}


		}

		.main_middle {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			z-index: 20;
			height: 50vh;
		}
	}




	.imgSize {
		width: 100%;
		max-width: 500px;
		height: 100%;
		margin: auto;
	}

	.svgStyle {
		width: 450px;
		height: 450px;
		position: absolute;
		top: 60%;
		left: 40%;
		margin-top: -100px;
		// margin-left: -250px;
	}

	.btnStyle {
		text-align: center;
		margin-top: 15px;
	}

	.div_svg {
		position: relative;
		height: 200px;
	}

	.pop_style {
		width: 90%;
	}

	.laba {
		width: 30px;
		height: 30px;
		margin-left: 10px;
	}

	.pop_con {
		background-image: url('../assets/image/pop_bg.png');
		height: 82vh;
		background-size: 100% 100%;
		width: 100%;
		overflow: hidden;
		padding-bottom: 40px;

		.pop_con_tab {
			padding: 30px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			z-index: 10;
			width: 100%;
			left: 0;
			// top: 10px;
			background-size: 100% 300px;
			background-image: url('../assets/image/pop_bg.png');

			.pop_con_tabimg {
				width: 190px;
				height: 70px;
				margin: 0 30px;
			}

		}
	}

	.pop_info {
		padding: 0 30px;
		color: #37506B;
		font-size: 22px;
		font-family: 'huiti';
		margin-top: 150px;
	}

	.aaa {
		height: 60vh;
		overflow-y: scroll;
		display: inline-block;
		margin: 0 auto;
		text-align: center;
		width: 100%;
	}

	.idiomTo {
		display: inline-block;
		margin: 10px;
		font-size: 23px;
		border-radius: 10px;
		color: white;
		// background-color: #41c9f1;
		background-image: url('../assets/image/chengyu_bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 150px;
		height: 50px;
		line-height: 50px;
		overflow: hidden;
		white-space: nowrap;
	}

	.idiomTo a {
		color: #37506B;
	}

	.idiomTo a:hover {
		color: #37506B;
		text-decoration: none;
	}

	.main_r_footer {
		margin: 30px 20px;
		color: #465C74;


		.main_r_f_title {
			font-size: 33px;
			font-family: 'cn';
			font-weight: 500;
		}

		.main_r_info {
			font-weight: 500;
			font-size: 23px;
			font-family: 'huiti';

			div {
				margin-right: 10px;
			}
		}
	}

	.idiomDetail {
		width: 100%;
		// max-width: 800px;
		margin: 0 auto;
	}

	.idiomTitle {
		padding: 10px 0 15px 0;
		font-size: 30px;
		color: #02427D;
		text-align: center;
		font-family: 'cn';
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		z-index: 10;
		width: 100%;
		left: 0;
		top: 0px;
		background-size: 100% 300px;
		background-image: url('../assets/image/pop_bg.png');
	}

	.idiomDetailTop {
		margin: 10px;
		padding: 10px;
	}

	.spanLeft {
		color: #ac6c0e;
		min-width: 165px;
	}

	.idiomTable {
		background-color: #fff;
		margin-top: 52px;
	}

	.idiomTable td {
		border: #E4E7ED solid 1px;
		padding: 10px 5px;
		font-size: 25px;
	}

	.fixed {
		position: fixed;
		z-index: 2300;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		bottom: 10px;
	}

	.close_icon {
		width: 125px;
		height: 50px;

	}

	.explainChineseBottom {
		padding: 10px
	}

	.explainChinese {
		line-height: 30px;
		letter-spacing: 2px;
	}

	.explainSpanTop {
		font-size: 26px;
		color: #02427D;
		font-family: 'cn';
		margin-bottom: 15px;
	}

	.itemDiv table {
		font-size: 15px;
		vertical-align: middle;
	}

	.itemDiv td {
		text-align: center;
		border: #1f7f40 solid 4px;
		/*background-color: #97e5b2;*/
		background-color: #ffd700;
		box-shadow: 0 0 2px 2px #0a4f32;
		width: 1%;

	}

	.td2 {
		background-image: url('../assets/image/tiangelan.png');
		background-repeat: no-repeat;
		height: 100px;
	}

	.itemDiv img {
		vertical-align: middle;
	}

	.explainChinese hr {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.exlainText {
		margin: 5px;
		line-height: 30px;
		letter-spacing: 2px;
		color: #034c1c;
	}

	.itemDiv {
		padding: 10px;
		max-width: 800px;
		margin: 0 auto;
	}

	.itemDiv img {
		display: inline-block;
	}

	.itemDiv .van-image__error {
		font-size: 5vh;
		max-font-size: 30px;
		line-height: 20px;
		top: -7px;
		left: 2px;
	}
</style>