<template>
	<div class="gameEnd">
		<div>
			<div class="form_con_title">
				<div class="form_con_title_l">
					◆
					<span class="line"></span>
				</div>
				<text class="wel_login">恭喜通关</text>
				<div class="form_con_title_r">
					<span class="line"></span> ◆
				</div>
			</div>
			<p class="red">总用时：{{ usetime }}秒
				<span style="margin-left: 25px;">错误次数：{{ errornum }}次</span>
			</p>

		</div><br>
		<div class="nextGameDiv">
			<img class="restarbtn" src="../assets/image/chongxin.png" @click="refresh" alt="">
			<img class="restarbtn" @click="nextGame" src="../assets/image/xiayiguan.png" alt="">
			<img src="../assets/image/close_b.png" alt="" class="restarbtn" @click="closeGameEnd">

		</div>
	</div>
</template>

<script>
	export default {
		name: "gameEnd",
		props: ["usetime", "errornum"],
		data() {
			return {
				baifenbi: 0,
			}
		},
		mounted() {

		},
		methods: {
			refresh() {
				this.$emit("refresh")
			},
			nextGame() {
				this.$emit("next")
			},
			closeGameEnd() {
				this.$emit("endGame")
			}
		}
	}
</script>

<style scoped>
	.gameEnd {
		color: white;
		font-size: 26px;
		font-weight: 500;
		margin: 0 auto;
		position: relative;
		top: 50%;
		height: 100%;
		background-size: 100%;
		/*偏移*/
		transform: translateY(-50%);
		background-image: url('../assets/image/hanziover_bg.png');
		background-repeat: no-repeat;
	}

	.red {
		color: #C93B26;
		font-family: 'cn';
	}

	.nextGameDiv {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.restarbtn {
		width: 145px;
		height: 55px;
		margin: 10px;
	}

	.form_con_title {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
		margin-top: 10px;
	}

	.form_con_title_l {
		display: flex;
		align-items: center;
		color: #C1A89C;
		font-size: 10px;
	}

	.form_con_title_r {
		display: flex;
		align-items: center;
		color: #C1A89C;
		font-size: 10px;
	}

	.line {
		width: 50px;
		margin: 0 -5px;
		border-bottom: 2px solid #C1A89C;
	}

	.wel_login {
		font-family: 'cn';
		color: #02427D;
		margin: 0 17px;
		font-size: 36px;
		font-weight: 600;
	}
</style>