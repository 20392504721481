<template>
	<div>
		<van-popup overlay-class="pop_style" v-model:show="setshow" :style="pop_style">
			<div class="set_con">
				<div style="width:100%;height:95%;position: relative;">
					<img class="main_con_gun" src="../assets/image/login_gun.png">
					<div class="set_con">
						<div class="form_con_title">
							<div class="form_con_title_l">
								◆
								<span class="line"></span>
							</div>
							<text class="wel_login">设置</text>
							<div class="form_con_title_r">
								<span class="line"></span> ◆
							</div>
						</div>
						<div>
							<van-cell-group :border="false" style="margin:20px">
								<van-cell class="input_style" title="学校编号" :value="schoolCode"></van-cell>
								<!--       <van-cell title="学校名称" :value="schoolName"></van-cell>-->
							</van-cell-group>
							<van-cell-group :border="false">
								<van-cell class="input_style" style="margin:20px;width:91%;overflow: hidden;" center
									title="开启声音">
									<template #right-icon>
										<van-switch :model-value="isCloseVoice" @update:model-value="onisCloseVoice"
											size="24" />
									</template>
								</van-cell>
							</van-cell-group>
							<van-cell-group :border="false">
								<van-cell class="input_style" title="意见反馈" is-link
									style="margin: 20px;width:91%;overflow: hidden;"
									@click="showFeedback=true;message='',setshow=false"></van-cell>

							</van-cell-group>
							<van-cell-group :border="false">
								<van-cell class="input_style" :title="`版本更新`+update" is-link @click="checkForUpdater()"
									style="margin: 20px;width:91%;overflow: hidden;"></van-cell>

							</van-cell-group>
							<div style="color: #02427D;margin:20px 25px">版本号：{{getVersion()}}</div>
							<img style="margin: 30px auto 20px;" @click="quit()" src="../assets/image/loginout.png"
								class="login_out" alt="">
						</div>
					</div>
					<img class="main_con_gun2" src="../assets/image/login_gun.png">
				</div>
			</div>
		</van-popup>
		<van-popup v-model:show="showFeedback" position="center" @touchstart='touchstart' @touchmove='touchmove'
			:style="pop_style">
			<div class="set_con">
				<div style="height: 100%;">
					<img class="main_con_gun" src="../assets/image/login_gun.png" style="top:0 !important">
					<div class="set_con">
						<div class="form_con_title">
							<div class="form_con_title_l">
								◆
								<span class="line"></span>
							</div>
							<text class="wel_login">意见反馈</text>
							<div class="form_con_title_r">
								<span class="line"></span> ◆
							</div>
						</div>
						<div class="setupFeedback centerStyleMargin">
							<van-field v-model="message" rows="6" autosize label="" type="textarea"
								placeholder="请输入反馈意见" show-word-limit></van-field>
							<img src="../assets/image/submit.png" class="setupBtn" alt="">
						</div>
					</div>
					<img class="main_con_gun2" src="../assets/image/login_gun.png" style="bottom: 0;">
				</div>

			</div>

		</van-popup>
		<!-- <div style="height:50px"></div>	 -->
		<!--  -->
		<div class="footer_fixed" :style="closeshow==true?'justify-content: flex-end':''">


			<img src="../assets/image/close4.png" v-if="closeshow" alt="" style="margin-right: 13px;"
				@click="close_system">
			<img style="margin: 0 14px;" v-show="backshow" @click="goBack()" src="../assets/image/back.png" alt="">
			<div class="align-center">
				<img src="../assets/image/small.png" alt="" style="margin-right: 13px;" @click="minimize()">
				<img @click="setting()" src="../assets/image/home_r_set.png" alt="">

				<img v-if="playshow" @click="quanping()" src="../assets/image/quan.png" alt="" style="margin-left:10px">
			</div>
			<!-- <img v-if="!playshow" @click="setting()" src="../assets/image/home_r_set.png" alt=""> -->
			<!-- <img v-if="playshow" @click="quanping()" src="../assets/image/quan.png" alt="" style="margin-left"> -->
		</div>
	</div>
</template>

<script>
	import {
		Dialog,
		Toast,
		Notify
	} from "vant";
	import {
		ref
	} from "vue"
	import {
		useStore
	} from "vuex";
	import audio from "@/audio/audio";
	import {
		invoke
	} from "@tauri-apps/api";
	import {
		ipc
	} from '@/utils/ipcRenderer';
	import {
		ipcApiRoute
	} from "@/request/ipcApi";

	export default {
		setup() {
			let images = {};
			const store = useStore();
			const isCloseVoice = ref(store.state.Voice);
			const onisCloseVoice = (newValue) => {
				if (isCloseVoice.value) {
					Notify({
						type: `danger`,
						message: `关闭声音`,
						duration: 500,
					})

				} else {
					Notify({
						type: `success`,
						message: `开启声音`,
						duration: 500,
					})
				}
				store.commit("getVoice", newValue)
				isCloseVoice.value = newValue;
				audio(0);
			}
			// todo 获取是否完善资料
			let isImproveInf = ref(false);
			const isEyeProtection = ref(false);

			return {
				images,
				isImproveInf,
				isEyeProtection,
				isCloseVoice,
				onisCloseVoice
			};
		},
		data() {
			return {
				setshow: false,
				pop_style: {
					width: '26%',
					backgroundColor: 'transparent'
				},
				showChangeNickname: false, // 修改昵称
				showZhanghao: false, // 展示账号
				phoneNumber: "", // 绑定的手机号
				phoneNumberNew: "", // 修改的新手机号
				yanzhengma: "", // 修改手机号的验证码
				step: 'start', // 修改手机号展示第一步
				showChangePassword: false, // 修改密码弹框
				password: '', // 原密码
				passwordNew: '', // 新密码p
				operatorTye: '', // 操作类型，修改密码为password,修改手机号为honeNumber
				showFeedback: false, // 意见反馈
				message: "", // 反馈意见
				schoolCode: localStorage.getItem('phone'),
				schoolName: "希望小学",
				backshow: false,
				closeshow: false,
				playshow: false,
				smallshow: false,
        update:null,
			}
		},
		mounted() {
			// console.log(this.$route.path)
			if (this.$route.path == '/') {
				this.backshow = false
				this.closeshow = true
				this.smallshow = true
			} else {
				this.backshow = true
				this.closeshow = false
				this.smallshow = false
			}
			if (this.$route.path == '/newIntroduce' || this.$route.path == '/posture' || this.$route.path ==
				'/healthySittingPosture' || this.$route.path == '/radicalDetail') {
				this.playshow = true
			} else {
				this.playshow = false
			}
  console.log("version>>",this.getVersion())
		},
		methods: {
      getVersion(){
     return   ipc.sendSync(ipcApiRoute.getVersion)
      },
    async  checkForUpdater(){
      this.update =   await ipc.invoke(ipcApiRoute.checkForUpdater)
      console.log("update >>",   this.update );

    },
			minimize() {
				ipc.invoke(ipcApiRoute.minimize)
				invoke("min")
			},
			close_system() {
				ipc.invoke(ipcApiRoute.close)
				invoke("exit")
			},
			quanping() {
				var a = document.getElementById('vv')
				// console.log(a.requestFullscreen)
				if (a.requestFullscreen) {
					a.requestFullscreen()
				}
			},
			goBack() {
				this.$router.go(-1)
			},
			setting() {
				this.setshow = true
				console.log(1, this.setshow)
			},
			quit() {
				Dialog.confirm({
						title: '提示',
						message: '是否退出登录？',
					})
					.then(() => {
						sessionStorage.clear()
						localStorage.clear()
						this.$router.push('/login')
					})
					.catch(() => {
						// on cancel
					});
			},
			phoneNext(type) {
				// 修改手机号和密码点击下一步，去验证手机号是否重复，并且获取验证码
				this.yanzhengma = "";
				if (type === 'phoneNumber') {
					if (!this.phoneNumberNew) {
						Dialog.alert({
							title: '提示',
							message: '请填写手机号',
						});
						return;
					}
					if (!this.phoneNumberNew || this.phoneNum !== this.phoneNumber || this.phoneNumber === this
						.phoneNumberNew) {
						Dialog.alert({
							title: '提示',
							message: '新手机号与原来的手机号相同,或者手机号输入有误，请修改重新提交',
						});
						return;
					}
				} else {
					if (this.password === this.passwordNew) {
						Dialog.alert({
							title: '提示',
							message: '新密码与原密码相同，不需要修改',
						});
						return;
					}
					let reg = /^[a-zA-Z\d#$@%&*]{6,12}$/;
					if (!reg.test(this.passwordNew)) {
						Dialog.alert({
							title: '提示',
							message: '密码只能是字母数字或者#$@%&*组成且长度为6-12位',
						});
						return false;
					}
					this.$api.post('auth/xgsms', {
						phone: this.phonenum,
						password: this.password,

					}).then(() => {
						this.$api.post('auth/xiugai', {
							sms: this.yanzhengma,
							pswnew: this.passwordNew
						}).then(
							(res) => {
								Notify({
									type: res.data['type'],
									message: res.data['message']
								})
							}
						)
					})

					// 调用服务判断原密码是否正确，并且获取验证码
				}
				// 调用服务获取验证码
				// axios
				this.step = 'twoPhone';
			},
			changePassword() {
				this.showChangePassword = true;
				this.step = 'startPhone';
				this.operatorTye = 'password';
				this.password = "";
				this.passwordNew = "";
			},
			onSubmit() {
				// 手机号修改提交，调用服务后台验证验证吗是否正确，正确则提交新手机号，以后即用新手机号登录
				if (this.operatorTye === 'phoneNumber') {
					// 修改手机号
					var suc = true;
					if (suc) {
						Toast.success('修改手机号成功');
						this.phoneNumber = this.phoneNumberNew;
						this.showZhanghao = false;
					} else {
						Toast.fail('修改手机号失败');
					}
				} else if (this.operatorTye === 'password') {

					if (suc) {
						Toast.success('修改密码成功');
						this.phoneNumber = this.phoneNumberNew;
						this.showChangePassword = false;
					} else {
						Toast.fail('修改密码失败');
					}
				}
			},
			submitFeedback() {
				if (!this.message) {
					Dialog({
						message: '请填写反馈意见再提交'
					});
					return false;
				}

				this.$api.post('api/yijian', {
					message: this.message,
					name: this.schoolCode
				}).then(
					() => {
						Dialog({
							message: '已提交'
						});
						this.showFeedback = false;
					}
				).catch(() => {
					Dialog({
						message: '提交失败'
					})
					this.showFeedback = false;

				})
				// console.log("提交反馈意见")
				// 提交反馈意见
			},
			aboutOurClick() {
				this.$router.push('aboutOur')
			},
			touchstart(e) {
				this.startY = e.touches[0].clientY
			},
			touchmove(e) {
				this.moveY = e.touches[0].clientY
				if (this.startY - this.moveY <= -100) {
					this.showChangePassword = false;
					this.showZhanghao = false;
					this.showFeedback = false;
					this.showChangeNickname = false;
				}
			}
		},

	}
</script>
<style>
	/deep/.van-hairline--top-bottom:after {
		border: none !important;
	}

	/deep/[class*=van-hairline]:after {
		border: none !important;
	}
</style>
<style lang="scss" scoped>
	.login_out {
		width: 75%;
		margin: 30px auto 0 auto;
		// height: 51px;
	}

	.footer_fixed {
		z-index: 30;
		position: fixed;
		bottom: 10px;
		width: 100%;
		margin: 0 auto;
		padding: 0 30px;
		left: 0;
		right: 0;
		// line-height: var(--footer-height);
		display: flex;
		align-items: center;
		justify-content: flex-end;

		img {
			width: 150px;
			height: 60px;
		}
	}

	.set_con {
		width: 100%;
		margin: 0 auto;

		position: relative;
		height: 100%;
		overflow: hidden;

		.main_con_gun {
			position: absolute;
			top: -5px;
			right: 0;
			left: 0;
			width: 100%;
			z-index: 2200;
		}

		.main_con_gun2 {
			position: absolute;
			bottom: -9px;
			right: 0;
			left: 0;
			width: 100%;
			z-index: 2200;
		}

		.set_con {
			background: #fff;
			width: 93%;
			height: 100%;
			margin: 10px auto;

			.form_con_title {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 40px 0 0 0;
				margin-top: 10px;
				font-family: 'cn';
			}

			.form_con_title_l {
				display: flex;
				align-items: center;
				color: #C1A89C;
				font-size: 2px;
			}

			.wel_login {
				font-family: 'cn';
				color: #02427D;
				margin: 0 17px;
				font-size: 30px;
				font-weight: 600;
			}

			.form_con_title_r {
				display: flex;
				align-items: center;
				color: #C1A89C;
				font-size: 2px;

			}

			.line {
				width: 60px;
				margin: 0 -5px;
				border-bottom: 2px solid #C1A89C;
			}
		}
	}

	.input_style {
		background-color: #F5F6F8;
		padding: 15px;
		border-radius: 10px;
	}

	.setupBtn {
		width: 80%;
		// height: 45px;
		margin: 20px auto;
	}

	.setupFeedback .van-field__control {}

	.setupFeedback .van-cell {
		margin: 20px auto;
		width: 90%;
		height: 220px;
		border: none !important;
		background-color: #F5F6F8;
	}

	.setup {
		width: 100%;
		max-width: 800px;
		margin: 10px auto;
	}

	.centerStyleMargin {
		margin: 10px auto;
		max-width: 800px;
	}
</style>