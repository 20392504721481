import {
	createRouter,

} from 'vue-router'
import api from "@/request/api";
import XXZX from "@/views/XXZX.vue"
import characterExercise from "@/views/characterExercise";
import store from '../store/index'
import loginreg from "@/views/loginreg";
import {
	Dialog
} from "vant";
import Nologin from "@/views/Nologin";
import video from "@/views/video";
import arcadeGame from "@/views/arcadeGame";
import zishi from "@/router/zishi";
import user from "@/router/user";
const routes = [

	{
		path: '/login',
		name: 'loginreg',
		component: loginreg,
		meta: {
			close_head: true,

		}
	},
	{
		path: '/shuxie',
		name: 'charachterExercise',
		component: characterExercise,
		meta: {
			requireAuth: true,
			project: true,
			title: 'project'
		}
	},
	{
		path: '/arcadeGame',
		name: 'arcadeGame',
		component: arcadeGame,
		meta: {
			requireAuth: true,
			project: true,
			title: 'project'
		}
	},
	{
		path: '/video',
		name: 'video',
		component: video,
		meta: {
			requireAuth: true,
			project: true,
			title: 'project'
		}
	},
	{
		path: '/',
		name: 'XXZX',
		component: XXZX,
		meta: {
			keepAlive: true, //设置页面是否需要使用缓存
			tabbar: true,
			title: '学习中心',
			return_close: true,
			requireAuth: true,

		},
	},
	{
		path: '/nologin',
		name: 'nologin',
		component: Nologin,
		meta: {
			tabbar: true,
			title: '当前未登录'
		},
	},
	{
		path: '/game',
		name: 'gameButton',
		component: () => import('../views/gameButton.vue'),
		meta: {
			requireAuth: true,
			project: true,
			bodycolor: '#fff',
			title: 'project'
		}
	}, {
		path: '/matchWrodGame',
		name: 'matchWrodGame',
		component: () => import('../views/matchWrodGame.vue'),
		meta: {
			requireAuth: true,
			project: true,
			title: 'project'
		}
	},
	{
		path: '/operate',
		name: 'operate',
		component: () => import('../views/operate.vue'),
		// props: route => ({param: route.query.param}),
		meta: {
			// return_close: true,
			requireAuth: true,
			title: 'project',
			return_close: true
		}
	}
].concat(zishi, user)
import {
// createWebHashHistory,
 createWebHistory
} from 'vue-router'
const router = createRouter({
	history:createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
	routes,

})

export default router
const colorto = (to) => {
	if (to.meta.bodycolor) {
		document.querySelector('body').setAttribute('style', `background-color: ${to.meta.bodycolor};`)
	} else {
		document.querySelector('body').setAttribute('style', "background-color:''")
	}
}
router.afterEach((to) => {
	colorto(to)
})

router.beforeEach((to, from, next) => {
	const op = localStorage.getItem("flag_auth") === '1' ?
		localStorage.getItem("auth") : sessionStorage.getItem("auth");

	if (to.name === "XXZX") {
		api.jhkmc().then((res) => {
			for (const i of res.data) {
				if (i["flag"] === 257) {
					store.state.level = 1;
				}
			}
			sessionStorage.setItem('jhkmc', JSON.stringify(res.data))
			let f = [];
			for (const argument of res.data) {
				f.push(argument["name"]);
			}
			store.commit("getSubjlist", f);

		})
	}

	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (op) { // 判断本地是否存在token
			if (to.meta.project) { //判断是否是收费项目
				api.post('auth/code', {
					auth: op,
					banben: store.state.seachALL,
					code: store.state.code,
					kmcdata: store.state.kmcData,
				}).then(() => {
					next()
				}).catch(() => {
					document.querySelector('body').setAttribute('style', "background-color:''")
					Dialog.alert({
						title: '提示',
						message: '对不起，您没有开通当前课程',
					})
					router.replace('/')
				})

			} else {
				next()
			}

		} else {
			// 未登录,跳转到登陆页面
			next({
				path: '/login'
			})
		}
	} else {
		next();
	}
});