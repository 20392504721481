<template>
	<div>
		<div class="xxzx">
			<img @click="searchShow=false" src="../assets/image/home_title.png" class="home_title" alt="">
			<div>
				<div style="display: flex;align-items: center;padding-right: 100px;position: relative">
					<div @click="searchShow=false" style="flex: 1;display: flex;align-items: center;margin-left:340px">

						<img src="../assets/image/home_haozi2.png" class="home_haozi" alt="">

					</div><van-search background="transparent" shape="round" v-model="value_search"
						placeholder="请输入搜索关键词" @clear="searchShow=false" @update:model-value="goSearch"></van-search>
				</div>
				<div id="pop">
					<van-popup v-model:show="searchShow" position="right" :overlay="false"
						:style="{height:'50%', width:'19%',position:'absolute',top:'450px',marginRight:'50px',background:'#fff',borderRadius:'15px'}">
						<van-list v-show="value_search">
							<van-cell-group>
								<van-cell style="border-bottom: 1px solid #E4E7ED;" :value="item['sx']"
									v-for="item in searchData" :key="item['kmc']" :title="item['kmc']" class="cell"
									is-link @click="searchclick(item['ziku'],item['kmc'], item['nj'])"
									:label="item['nj']">
									<!-- 使用 title 插槽来自定义标题 -->
									<template #title>
										<span class="custom-title">{{ item['kmc'] }} &nbsp;</span>
										<van-tag type="danger" v-show="item['flag']">课内字</van-tag>
										<van-tag type="primary" v-show="!item['flag']">课名含有</van-tag>
										<br>
										<van-tag type="success"
											v-show="$store.state.subjlist.indexOf(item['nj']+'册')!==-1">已开通</van-tag>
										<van-tag type="warning"
											v-show="$store.state.subjlist.indexOf(item['nj']+'册')===-1">未开通</van-tag>
									</template>
								</van-cell>
							</van-cell-group>
						</van-list>
					</van-popup>
				</div>
			</div>


			<div class="main_con">
				<div class="l_con" @click="searchShow=false">
					<div class="l_con_top">
						<div style="display: flex;align-items: center;">
							<img class="pen" src="../assets/image/pen.png" alt="">
							<div class="l_con_h1">选择你要练习的阶段</div>
						</div>

						<van-dropdown-menu duration="0" class="menu" direction="down" active-color="#02427D"
							:overlay="false">
							<van-dropdown-item v-model="value" :options="options"
								@change="$store.commit('getseachALL', value)" />
							<!-- 	<van-dropdown-item title="筛选" ref="item">
								<van-cell center title="全部显示(识字，课文，语文园地)">
									<template #right-icon>
										<van-switch v-model="switch0" size="24"></van-switch>
									</template>
								</van-cell>
								<van-cell center title="识字" v-show="!switch0">
									<template #right-icon>
										<van-switch v-model="switch1" size="24"></van-switch>
									</template>
								</van-cell>
								<van-cell center title="课文" v-show="!switch0">
									<template #right-icon>
										<van-switch v-model="switch2" size="24"></van-switch>
									</template>
								</van-cell>
								<van-cell center title="语文园地" v-show="!switch0">
									<template #right-icon>
										<van-switch v-model="switch3" size="24"></van-switch>
									</template>
								</van-cell>
							</van-dropdown-item> -->
						</van-dropdown-menu>
						<!-- <div class="flex">
						<div class="flex drop">
							{{value}}

							<van-icon name="arrow-down" color="#fff" />

						</div>
						<div class="flex drop" style="margin-left: 20px;">
							全部显示
							<van-icon name="arrow-down" color="#fff" />
						</div>
					</div> -->
					</div>

					<div class="l_list flex">
						<div class="l_item" v-for="(item,index) in njall" :key="index">
							<div class="l_item_re">
								<img :src="classList[index].bg" alt="">
								<div class="l_item_ab">
									<div class="l_item_title">{{item}}</div>
									<div>
										<div class="l_item_con">共上下两册，内容包含《识字》、《课文》、《语文园地》，通过练字、观看视频和游戏的方式进行系统练习！</div>
										<div class="flex l_item_buttons">
											<van-button class="button" round
												@click="kaitong(item,'上')?goDetail(item,'上'):no_ok()">上册</van-button>
											<van-button class="button" round
												@click="kaitong(item,'上')?goDetail(item,'下'):no_ok()">下册</van-button>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="r_con">
					<div class="r_con_item" v-for="(item,index) in rList" @click="clickToRouter(item.path)"
						:key="index">
						<img :src="item.img" alt="">
						<span>{{item.name}}</span>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>

</template>

<script>
	import Footer from "@/components/Footer";
	// import RadicalComp from "../components/RadicalComp";
	import base from "@/request/base";
	import {
		useStore
	} from "vuex";
	import {
		ref
	} from "vue";
	import {
		Dialog
	} from "vant";
	import {
		loaclData,
		czData,
		rjbb
	} from "@/link/link";
	import * as lod from "lodash";
	export default {
		name: "XXZX",
		components: {
			Footer
		},
		// mounted() {
		//   this.$api.jhkmc().then((res) => {
		//     for (const argument of res.data['jhkmc']) {
		//       this.subjlist.push(argument['name'])
		//     }
		//   })
		// },

		data() {
			return {
				value_search: '',
				searchShow: false,
				subjlist: [],
				value: this.$store.state.seachALL,
				hengxianstyle: {
					color: "#1989fa",
					borderColor: "#1989fa",
					padding: "0 16px",
				},
				switch0: true,
				switch1: true,
				switch2: true,
				switch3: true,
				active_home: "0",
				activeNames: "0",
				tactiveNames: "0",
				activeNames2: "0",
				backList: ['../assets/image/home_yi.jpg', '../assets/image/home_er.jpg'],
				rList: [{
						name: '新田格介绍',
						img: require('../assets/image/home_r_1.png'),
						path: "newIntroduce"

					},
					{
						name: '握笔姿势',
						img: require('../assets/image/home_r_2.png'),
						path: "posture"
					},
					{
						name: '健康坐姿',
						img: require('../assets/image/home_r_3.png'),
						path: "healthySittingPosture"
					},
					{
						name: '基本笔画',
						img: require('../assets/image/home_r_4.png'),
						path: "jibenbihua"
					},
					{
						name: '偏旁部首',
						img: require('../assets/image/home_r_5.png'),
						path: "radicalview"
					},
					{
						name: '基础字根',
						img: require('../assets/image/home_r_6.png'),
						path: "radicalDetail"
					},
				],
				classList: [{
						id: 1,
						name: '一年级',
						bg: require('../assets/image/home_yi.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					},
					{
						id: 2,
						name: '二年级',
						bg: require('../assets/image/home_er.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					},
					{
						id: 2,
						name: '三年级',
						bg: require('../assets/image/home_san.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					},
					{
						id: 2,
						name: '四年级',
						bg: require('../assets/image/home_si.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					},
					{
						id: 2,
						name: '五年级',
						bg: require('../assets/image/home_wu.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					},
					{
						id: 2,
						name: '六年级',
						bg: require('../assets/image/home_liu.png'),
						title: '共上下两册，内容包含《识字》、《课文》、《语文园地》等，通过练字、看视频和游戏的方式进行系统练习！'
					}
				]
			};
		},
		setup() {
			// const store = useStore();

			// const store = useStore();
			// // 在页面加载时读取sessionStorage里的状态信息
			//
			// if (localStorage.getItem("store")) {
			//   store.replaceState(Object.assign({},
			//       store.state, JSON.parse(localStorage.getItem("store"))))
			// } //初始化所有课程
			// // store.dispatch('getALL');
			// //在页面刷新时将vuex里的信息保存到sessionStorage里
			// window.addEventListener("beforeunload", () => {
			//   window.location.reload()
			//   localStorage.setItem("store", JSON.stringify(store.state))
			// });

			//   window.versions.ping().then((res)=>console.log("ping =>",res))
			const value1 = ref(0);
			const value2 = ref('a');
			const option1 = [{
					text: '全部商品',
					value: 0
				},
				{
					text: '新款商品',
					value: 1
				},
				{
					text: '活动商品',
					value: 2
				},
			];
			const option2 = [{
					text: '默认排序',
					value: 'a'
				},
				{
					text: '好评排序',
					value: 'b'
				},
				{
					text: '销量排序',
					value: 'c'
				},
			];
			const tactiveNames2 = ref({
				一年级: "0",
				二年级: "0",
				三年级: "0",
				四年级: "0",
				五年级: "0",
				六年级: "0"
			});
			const store = useStore();
			const icons = {
				nj: `${base.ass}image/icon/fenzu2x.png`,
				ce: `${base.ass}image/icon/zhinan2x.png`,
				ke: `${base.ass}image/icon/yijianfankui2x.png`,
			};
			// const loaclData = store.state.ALL;
			const seachAll = store.state.seachALL;
			console.log(seachAll)
			const cS = loaclData[seachAll]
			console.log(cS)

			function aaa() {
				tactiveNames2.value = {
					一年级: "0",
					二年级: "0",
					三年级: "0",
					四年级: "0",
					五年级: "0",
					六年级: "0"
				}
				// api.jhkmc().then((res) => {
				//   let f = [];
				//   for (const argument of res.data) {
				//     f.push(argument["name"]);
				//   }
				//   store.commit("getSubjlist", f);
				//
				// })
			}


			function kaitong(nj, ud) {
				// console.log(store.state.subjlist.indexOf(nj + ud + '册'))
				return store.state.subjlist.indexOf(nj + ud + '册') != -1;

			}
			if (sessionStorage.getItem("store")) {
				store.replaceState(Object.assign({},
					store.state, JSON.parse(sessionStorage.getItem("store"))))
			} //初始化所有课程
			// store.dispatch('getALL');
			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener("beforeunload", () => {
				window.location.reload()
				let cache_js = JSON.stringify({
					zierData: store.state.zierData,
					code: store.state.code,
					kmcData: store.state.kmcData,
					currentVolume: store.state.currentVolume, // 当前册课程,
					currentSubjectItem: store.state.currentSubjectItem, // 当前课程
					cont_zi: store.state.cont_zi,
					subjlist: store.state.subjlist,
					level: store.state.level,
				});
				sessionStorage.setItem("store", cache_js)
			});
			const swiperList = [
				`${base.ass}xiaoyuanlunbao.jpg`,
			];
			return {
				icons,
				swiperList,
				czData,
				cS,
				kaitong,
				aaa,
				tactiveNames2,
				value1,
				value2,
				option1,
				option2,
			};
		},
		created() {},
		computed: {
			index_title() {
				const sa = this.$route.meta.title
				if (sa === 'project') {
					return this.$store.state.kmcData
				} else {
					return sa
				}
			},
			options() {
				return this.$store.state.option;
			},
			njall() {
				return this.$store.state.nj_all;
			},
			level() {
				return this.$store.state.level;
			},
			searchData() {
				const tempArr = [];

				rjbb.forEach((v) => {

					if (this.value_search === v.ziku) {

						tempArr.push({
							kmc: v.kmc,
							nj: v.nj,
							sx: v.zilei,
							flag: true,
							ziku: v.ziku
						})


					}
				})

				lod.forEach(loaclData[this.$store.state.seachALL], (value, key) => {


					lod.forEach(value, (value1, key1) => {
						if (value1.length !== 0) {
							value1.forEach((value2) => {
								if (value2.indexOf(this.value_search) !== -1 && this
									.value_search !== '')
									// console.log(value1, value)
									tempArr.push({
										kmc: value2,
										nj: key,
										sx: key1,
										flag: false,
										ziku: null
									})
							})

						}
					});
				})
				//console.log(tempArr);

				return tempArr
			}
		},
		mounted() {
			console.log(window.innerHeight)
			// if (this.searchData.length != 0) {
			// 	this.searchShow = true
			// }
		},
		methods: {
			goSearch() {
				console.log(this.value_search, 222)
				if (this.value_search != '') {
					this.searchShow = true
				}

			},
			clickToRouter(routerPath) {
				if (routerPath === 'radicalDetail') {
					localStorage.setItem("radicalparam", '14');
				}
				this.$router.push(routerPath);
			},
			getBtn(nianji) {
				setTimeout(() => {
					this.$refs[nianji].open();
				}, 1);
			},
			goDetail(item, index) {
				console.log(item, index)
				const list = JSON.stringify(loaclData[this.value])
				this.$router.push({
					path: '/operate',
					query: {
						name: item + index,
						list: list
					}
				})
				this.$store.commit('getcode', [item, index])
				this.$store.commit("set_cont_zi", 0)

			},
			no_ok() {
				Dialog.alert({
					title: '提示',
					message: '对不起，您没有开通当前课程',
				})
			},
			day(nj, ud, item, to) {
				// 获取当前册所有课程
				// let currentClass = nj + ud;
				// let classSubject = this.cS[currentClass];
				// let data = [];
				// for (let j of classSubject['识字']) {
				// 	data.push(j);
				// }
				// for (let k of classSubject['课文']) {
				// 	data.push(k);
				// }
				// for (let p of classSubject['语文园地']) {
				// 	data.push(p);
				// }
				// this.$store.commit("getCurrentVolume", data);
				// this.$store.commit("getCurrentSubjectItem", item);
				// this.$store.commit("set_cont_zi", 0)

				this.$api.sux(item, nj, ud).then(() => {
					this.$router.push(to);
				});
			},
			onczl(item, to) {

				this.$store.commit("getCurrentSubjectItem", item);
				this.$store.commit("set_cont_zi", 0)
				this.$api.sux(item, "初", "上").then(() => {
					this.$router.push(to);
				});
			},
			searchclick(ziku, item, nj) {

				var ud = nj.slice(-1)
				var nj1 = nj.slice(0, 3)
				// console.log(ud, nj1)

				this.$api.sux(item, nj1, ud).then(() => {
					this.searchShow = !this.searchShow;
					this.value_search = "";
					this.$api.post('auth/code', {
						auth: localStorage.getItem('auth'),
						banben: this.$store.state.seachALL,
						code: this.$store.state.code,
						kmcdata: this.$store.state.kmcData,
					}).then(() => {
						// console.log(res.data)

						this.$store.commit("set_cont_zi", this.$store.state.zierData.indexOf(ziku))
						if (this.$route.name === "charachterExercise") {
							this.reload()
						} else {
							this.$router.push('/shuxie')
						}


					}).catch(() => {
						if (this.$route.name === 'charachterExercise') {
							this.$router.push('/')
						}
						Dialog.alert({
							title: '提示',
							message: '对不起，您没有开通当前课程',
						})
					})

				})
			}
		},
	};
</script>
<style>
	.menu {
		position: relative;
	}

	.menu .van-dropdown-menu__bar {
		box-shadow: none !important;
		background: none !important;
	}

	.menu .van-dropdown-item {
		width: 210px;
		height: auto;
		top: 60px !important;
		/* left: 15px; */
		z-index: 50;
		height: 300px;
		position: absolute;
	}

	.menu .van-dropdown-menu__title {
		color: #fff;
	}

	.menu .van-dropdown-menu__item {
		margin: 0 10px;
		background-color: #5C7999;
		width: 168px;
		color: #fff;
		height: 50px;
		border-radius: 40px;
	}

	.van-search__content {
		background: #fff !important;
		border: 1px solid #02427D !important;
		width: 250px;
	}

	.van-search__field {
		height: 44px;
	}

	.van-field__left-icon .van-icon {
		font-size: 22px;
	}

	/deep/.van-dropdown-item__content {
		border-radius: 10px;
	}

	.van-dropdown-item__content {
		border-radius: 10px !important;
	}

	#pop .cell {
		background-color: #fff;
	}

	.van-ellipsis {
		font-size: 20px;
	}
</style>
<style scoped>
	body {
		overflow: hidden;
		height: 100vh;
		/* height: 1080px; */
	}

  /* 设置滚动条滑块的颜色 */
  ::-webkit-scrollbar-thumb {
    background-color:#02427D; /* 滚动条滑块的背景色 */
  }



	@font-face {

		font-family: 'cn';
		src: url("../assets/font/SourceHanSerifCN-Bold.otf");
	}

	@font-face {

		font-family: 'al';
		src: url("@/assets/font/AlibabaPuHuiTi-2-55-Regular.ttf");
	}

	.flex {
		display: flex;
	}

	.xxzx {
		width: 100%;
		background-size: 100% 100%;
		transition: background 3s ease;
		background-position: 0 0;
		background-image: url('../assets/image/home_bg.jpg');
		padding-top: 14px;
		/* background-image: url('../assets/image/home_bg.jpg'); */
	}

	.home_title {
		width: 730px;
		/* height: 70px; */
		margin: 0 auto;
		/* padding-top: 20px; */

	}

	.home_haozi {
		/* 812x148 */
		width: 350px;
		/* height: 74px; */
		margin: 15px auto;
	}

	.main_con {
		display: flex;
		width: 100%;
		height: 100vh;
		justify-content: space-between;
		padding: 0px 20px;
	}

	.l_con {
		background-image: url('../assets/image/home_con_bg.png');
		background-size: 100% 100%;
		transition: background 3s ease;
		background-position: 50% 50%;
		width: 72%;
		height: 70vh;
		border-radius: 25px;
	}

	.l_item_con {
		color: #606266;
		font-size: 70%;
		padding: 0 5px;
		font-family: 'al';
	}

	.l_item_title {
		margin: 15px 0;
		font-size: 120%;
	}

	.l_con_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 40px 50px 5px 37px;
	}

	.pen {
		width: 80px;
	}

	.l_con_h1 {
		font-family: 'cn';
		font-weight: 1000;
		font-size: 40px;
		color: #fff;
		text-shadow: 2px 2px 0px #00000040;
	}

	.r_con {
		background-image: url('../assets/image/home_con_bg.png');
		background-size: 100% 100%;
		transition: background 3s ease;
		background-position: 50% 50%;
		width: 30%;
		border-radius: 25px;
	}

	.drop {
		background-color: #5C7999;
		width: 190px;
		height: 60px;
		border-radius: 30px;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 22px;
	}

	.l_list {
		padding: 0 10px;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.l_item {
		width: 31%;
		height: 30%;
		margin: 10px 0;
	}

	.l_item_re {
		position: relative;
		height: 100%;
	}

	.l_item_buttons {
		/* margin-top: 32px; */
		bottom: 28%;
		right: 5%;
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 400;
		justify-content: flex-end;
	}

	.button {
		border: 2px solid #02427D;
		color: #02427D;
		margin: 0 13px 0 0px;
		width: 72px;
		height: 30px;
	}

	.l_item img {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.l_item_ab {
		position: absolute;
		top: 10%;
		height: 100%;
		z-index: 20;
		right: 0;
		left: 13%;
	}

	.r_con {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-evenly;
		padding: 20px 0;
		height: 70vh;
		width: 27%;
	}

	.r_con_item {
		width: 40%;
		padding: 20px 0;
		height: 27%;
		background-image: url('../assets/image/home_r_bg.png');
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #02427D;
	}

	.r_con_item img {
		width: 55%;
		/* height: 66%; */
	}

	.r_con_item span {
		margin-top: 8px;
		font-weight: 1000;
		font-family: 'cn';
		font-size: 20px;
	}

	.fixed {
		position: fixed;
		z-index: 20;
		width: 100%;
		right: 0px;
		padding-right: 20px;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.setting {
		width: 130px;
		height: 50px;
	}

	@media screen and (min-width : 992px) {
		body {}
	}
</style>