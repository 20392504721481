<template>
	<div>
		<audio src="" id="eventAudio"></audio>
		<router-view v-slot="{ Component }" :key="$route.params.Id" v-if="isRouterAlive">
			<keep-alive>
				<component :is="Component" v-if="$route.meta.keepAlive" />
			</keep-alive>
			<component :is="Component" v-if="!$route.meta.keepAlive" />
		</router-view>
	</div>
	<!-- <ScaleBox :width="1920" :height="1080" bgc="transparent" :delay="100"> -->

	<!-- 	<template>
		<router-view />
	</template> -->
	<!-- </ScaleBox> -->

</template>

<script>
	// import ScaleBox from "vue3-scale-box";
	// import devPixelRatio from "@/store/devicePixelRatio.js";

	import {
		useRouter
	} from "vue-router";
	import {
		useStore
	} from "vuex";

	import {
		rjbb,
		loaclData
	} from "@/link/link";
	import * as lod from "lodash";
	import {
		Dialog
	} from "vant";


	export default {
		// created() {
		// 	new devPixelRatio().init(); // 初始化页面比例
		// },
		name: "App",
		// components: {
		// 	ScaleBox
		// },
		data() {
			return {
				value_search: '',
				searchShow: false,
				isRouterAlive: true
			}
		},
		provide() {
			return {
				reload: this.reload
			}
		},

		setup() {
			const store = useStore();

			// const store = useStore();
			// // 在页面加载时读取sessionStorage里的状态信息
			//
			// if (localStorage.getItem("store")) {
			//   store.replaceState(Object.assign({},
			//       store.state, JSON.parse(localStorage.getItem("store"))))
			// } //初始化所有课程
			// // store.dispatch('getALL');
			// //在页面刷新时将vuex里的信息保存到sessionStorage里
			// window.addEventListener("beforeunload", () => {
			//   window.location.reload()
			//   localStorage.setItem("store", JSON.stringify(store.state))
			// });
			if (sessionStorage.getItem("store")) {
				store.replaceState(Object.assign({},
					store.state, JSON.parse(sessionStorage.getItem("store"))))
			} //初始化所有课程
			// store.dispatch('getALL');
			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener("beforeunload", () => {
				window.location.reload()
				let cache_js = JSON.stringify({
					zierData: store.state.zierData,
					code: store.state.code,
					kmcData: store.state.kmcData,
					currentVolume: store.state.currentVolume, // 当前册课程,
					currentSubjectItem: store.state.currentSubjectItem, // 当前课程
					cont_zi: store.state.cont_zi,
					subjlist: store.state.subjlist,
					level: store.state.level,
				});
				sessionStorage.setItem("store", cache_js)
			});

			const router = useRouter()
			const goBack = () => window.history.length > 1 ? router.go(-1) : router.push('/');
			return {
				goBack,
			};
		},
		computed: {
			index_title() {
				const sa = this.$route.meta.title
				if (sa === 'project') {
					return this.$store.state.kmcData
				} else {
					return sa
				}
			},
			level() {
				return this.$store.state.level
			},
			searchData() {
				const tempArr = [];

				rjbb.forEach((v) => {

					if (this.value_search === v.ziku) {

						tempArr.push({
							kmc: v.kmc,
							nj: v.nj,
							sx: v.zilei,
							flag: true,
							ziku: v.ziku
						})


					}
				})

				lod.forEach(loaclData[this.$store.state.seachALL], (value, key) => {
					lod.forEach(value, (value1, key1) => {
						if (value1.length !== 0) {
							value1.forEach((value2) => {
								if (value2.indexOf(this.value_search) !== -1 && this
									.value_search !== '')
									// console.log(key1, key)
									tempArr.push({
										kmc: value2,
										nj: key,
										sx: key1,
										flag: false,
										ziku: null
									})
							})

						}
					});
				})
				//console.log(tempArr);

				return tempArr
			}
		},

		methods: {
			reload() {
				this.isRouterAlive = false
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			},

			searchclick(ziku, item, nj) {
				var ud = nj.slice(-1)
				var nj1 = nj.slice(0, 3)
				// console.log(ud, nj1)

				this.$api.sux(item, nj1, ud).then(() => {
					this.searchShow = !this.searchShow;
					this.value_search = "";
					this.$api.post('auth/code', {
						auth: localStorage.getItem('auth'),
						banben: this.$store.state.seachALL,
						code: this.$store.state.code,
						kmcdata: this.$store.state.kmcData,
					}).then(() => {
						// console.log(res.data)

						this.$store.commit("set_cont_zi", this.$store.state.zierData.indexOf(ziku))
						if (this.$route.name === "charachterExercise") {
							this.reload()
						} else {
							this.$router.push('/shuxie')
						}


					}).catch(() => {
						if (this.$route.name === 'charachterExercise') {
							this.$router.push('/')
						}
						Dialog.alert({
							title: '提示',
							message: '对不起，您没有开通当前课程',
						})
					})

				})
			},
			onClickRight() {
				this.searchShow = !this.searchShow;
				this.value_search = "";
			}
		},
	}
</script>
<style>

div::-webkit-scrollbar {
  width: 6px;
  height: 50%;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background-color: #02427D;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#02427D, 100%);
}
</style>
<style lang="scss">
	@import "~vant/lib/index.css";

	body {
		width: 100%;
		// height: 100vh;
		padding: 0;
		margin: 0;
		overflow: hidden;
	}

	.align-center {
		align-items: center;
		display: flex;
	}

	// @font-face {

	// 	font-family: 'cn';
	// 	src: url("@/assets/font/a.otf");
	// }
	@font-face {

		font-family: 'cn';
		src: url("@/assets/font/SourceHanSerifCN-Bold.otf");
	}

	@font-face {

		font-family: 'al';
		src: url("@/assets/font/AlibabaPuHuiTi-2-55-Regular.ttf");
	}

	#pop .van-popup--top {
		top: 50px;
	}

	#pop .van-overlay {
		margin-top: 100px;
	}

	#pop .van-popup {
		background-color: #f4f4f4;
	}

	#pop .cell {
		background-color: #f4f4f4;
	}

	.maoboli {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background-color: rgba(238, 238, 238, 0.6);
	}
</style>