const zishi = [{
    path: '/radicalview',
    name: 'radicalview',
    component: () => import('../views/mainCenterInfo/radicalview.vue'),
    meta: {
        requireAuth: true,
        title: '偏旁部首'

    },
}, {
    path: '/jibenbihua',
    name: 'jibenbihua',
    component: () => import('../views/mainCenterInfo/jibenbihua.vue'),
    meta: {
        requireAuth: true,
        title: '基本笔画'
    },
},
    {
        path: '/radicalDetail',
        name: 'radicalDetail',
        component: () => import('../views/mainCenterInfo/radicalDetail.vue'),
        meta: {
            requireAuth: true,
            close_head: true

        },
    },
    {
        path: '/newIntroduce',
        name: 'newIntroduce',
        component: () => import('../views/mainCenterInfo/newIntroduce.vue'),
        meta: {
            title: '新田格介绍'

        },
    },
    {
        path: '/posture',
        name: 'posture',
        component: () => import('../views/mainCenterInfo/posture.vue'),
        meta: {
            title: '握笔姿势',
            bodycolor: '#fabd69'
        },
    },
    {
        path: '/healthySittingPosture',
        name: 'healthySittingPosture',
        component: () => import('../views/mainCenterInfo/healthySittingPosture.vue'),
        meta: {
            title: '健康坐姿',
            requireAuth: true,
        },
    }
]
export default zishi;
