import {
	createStore
} from 'vuex'
import axios from "axios";
// import api from "@/request/api";

async function charDataLoaderEmbed(char,onLoad) {
	let res = await 	axios.get(`http://oss.newtiange.com/hanzi/hanzi-writer-data/${char}.json`)
	onLoad(res.data)
}

export default createStore({
	state: {
		//***书写参数
		headPortrait: "",
		stroke: {
			padding: 60,
			width: 468.28,
			height: 468.28,
			strokeColor: '#000000',
			radicalColor: '#000000',
			charDataLoader: charDataLoaderEmbed,
		},
		updownstroke: {
			padding: 60,
			width: 468.28,
			height: 468.28,
			showCharacter: false,
			showOutline: false,
			strokeColor: "#000000",
			radicalColor: '#000000',
			charDataLoader:charDataLoaderEmbed,
		},
		strokeOrderAnimation: {
			padding: 60,
			width: 468.28,
			height: 468.28,
			showCharacter: false,
			showOutline: true,
			strokeColor: '#000000',
			radicalColor: '#000000',
			charDataLoader:charDataLoaderEmbed,
			// radicalColor: '#c9a10f',
		},
		silentWriting: {
			leniency: 1,
			padding: 60,
			width: 468.28,
			height: 468.28,
			showHintAfterMisses: 1,
			showCharacter: false,
			showOutline: false,
			highlightOnComplete: false,
			drawingWidth: 40,
			charDataLoader:charDataLoaderEmbed,
		},
		writerPractice: {
			leniency: 1,
			padding: 60,
			width: 468.28,
			height: 468.28,
			strokeHighlightSpeed: 1,
			showHintAfterMisses: 1,
			showCharacter: false,
			drawingWidth: 40,
			charDataLoader:charDataLoaderEmbed,
		},
		arcadeGameStroke:{
				leniency: 0.65,
				padding: 60,
				width: 468.28,
				height: 468.28,
				strokeHighlightSpeed: 1,
				showHintAfterMisses: 1,
				showCharacter: false,
			charDataLoader:charDataLoaderEmbed,
		},
		nj_all: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
		nj_all2: [{
				text: '一年级上册',
				value: '一年级上',
				status: true
			},
			{
				text: '一年级下册',
				value: '一年级下',
				status: true

			},
			{
				text: '二年级上册',
				value: '二年级上',
				status: true
			},
			{
				text: '二年级下册',
				value: '二年级下',
				status: true
			},
			{
				text: '三年级上册',
				value: '三年级上',
				status: true
			},
			{
				text: '三年级下册',
				value: '三年级下',
				status: true
			},
			{
				text: '四年级上册',
				value: '四年级上',
				status: true
			},
			{
				text: '四年级下册',
				value: '四年级下',
				status: true
			},
			{
				text: '五年级上册',
				value: '五年级上',
				status: true
			},
			{
				text: '五年级下册',
				value: '五年级下',
				status: true
			},
			{
				text: '六年级上册',
				value: '六年级上',
				status: true
			},
			{
				text: '六年级下册',
				value: '六年级下',
				status: true
			}
		],
		option: [{
				text: '人教部编版',
				value: 'RJBB'
			},
			/* {text: '人教54制', value: 'rj54z'},
			 {text: '鲁教版', value: 'lj'},
			 {text: '冀教版', value: "jj"},
			 {text: '苏教版', value: "sj"},
			 {text: '北师大版', value: "bsd"},
			 {text: '语文A版', value: "ywA"},
			 {text: '语文S版', value: "ywS"},
			 {text: '京教版', value: "bj"},*/
		],
		// ALL: require('../assets/json/cache_all2021528.json'),
		//rjbb_zi: require("../assets/json/rjbb_zi2021528.json"),
		seachALL: 'RJBB',
		classValue: 0,
		zierData: [],
		code: [],
		setnj: '',
		kmcData: '',
		Voice: true,
		areaList: require('../assets/json/city.json'),
		currentVolume: '', // 当前册课程,
		currentSubjectItem: '', // 当前课程
		cont_zi: 1,
		subjlist: [],
		level: 0,
	},
	mutations: {
		set_level(state, data) {
			state.level = data
		},
		set_cont_zi(state, data) {
			state.cont_zi = data;
		},
		getCurrentVolume(state, data) {
			state.currentVolume = data;
		},
		getCurrentSubjectItem(state, data) {
			state.currentSubjectItem = data;
		},
		getseachALL(state, data) {
			state.seachALL = data
		},
		getclassALL(state, data) {
			state.classValue = data
		},
		getzierData(state, data) {
			state.zierData = data
		},
		getcode(state, data) {
			state.code = data
		},
		getkmcData(state, data) {
			state.kmcData = data
		},
		getVoice(state, data) {
			state.Voice = data
		},
		getSubjlist(state, data) {
			state.subjlist = data
			console.log(data)
		},


	},
	actions: {
		// getALL(context) {
		//     api.post('all', {
		//         all: 'all'
		//     }).then(report => {
		//
		//         context.commit('getALL', report.data);
		//         //数据整合，创造数组便于搜索功能
		//
		//     })
		//
		// }
	},

	modules: {}
})