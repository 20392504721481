import base from "@/request/base";
import store from "../store/index";

const muisc = (index) => {
    let audio0 = [`${base.ass}audio/anniu.mp3`]
    if (store.state.Voice) {
        let buttonAudio = document.getElementById('eventAudio');
        buttonAudio.setAttribute('src', [audio0][index])
        buttonAudio.play()
    }
}

export default muisc;