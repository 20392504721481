import base from "./base";
import axios from "./http";
import store from '../store/index'

const api = {
    get(url) {
        return axios.get(`${base.ass}${url}`)
    },
    post(url, data) {
        return axios.post(`${base.dev}${url}`, data);
    },
    shuxie(kmc, banben, nj) {
        return this.post(`shuxie`, {
            kmc: kmc,
            banben: banben,
            nj: nj
        })
    },
    sux(item, nj, ud) {
        return this.shuxie(item, store.state.seachALL, nj + ud)
            .then(report => {
                store.commit('getzierData', report.data)
                store.commit('getcode', [nj, ud])
                store.commit('getkmcData', nj + ud + '  ' + item)
            })
    },
    jhkmc() {
        return axios.post(`${base.dev}auth/jihuokmc`, {
            phone: localStorage.getItem('phone'),
            banben: store.state.seachALL
        })
    }

}

export default api;
